<template lang="html">
  <div id="results-page">

    <section class="breadcrumb-section">
      <div class="container oversized-container">
        <span>SubCategorías</span>
        <span class="line">/</span>
        <span>{{ subcategory.name }}</span>
      </div>
    </section>

    <section class="container oversized-container products-section">
      <div class="row mb-3">
        <div class="col-12 col-s-title">
          <h2 class="title">{{ subcategory.name }}Subcategoría</h2>
        </div>
      </div>

      <div class="row align-items-start">

        <!-- Mobile filters -->
        <div class="col-12 col-filters-mobile">
          <div class="bg-filter-mv" v-if="showFilters == true" @click="showFilters = false"></div>
          <a class="btn-filters" @click="showFilters = !showFilters">
            <i class="fas fa-filter"></i><span>Ver filtros</span>
          </a>
        </div>

        <div class="col-lg col-filters" v-bind:class="{ 'show-filters': showFilters == true }">
          <h4 class="_title">
            <span>Filtros de Búsqueda</span>
            <a class="btn-hide" @click="showFilters = false"><i class="fas fa-chevron-left"></i></a>
          </h4>

          <b-form class="filters-container" @submit="onSubmitFilters">

            <div class="box-filters">
              <a class="btn-action"><i class="sy">-</i> Marca</a>

              <b-form-group class="box-filters-group" v-slot="{ ariaDescribedby }">
                <b-form-checkbox-group
                  v-model="filtersForm.brands"
                  :aria-describedby="ariaDescribedby"
                  name="flavour-2">
                  <b-form-checkbox :value="br.id" v-for="(br,indx) in brandsOpcs" :key="'bra'+indx">{{br.name}}</b-form-checkbox>

                </b-form-checkbox-group>
              </b-form-group>
            </div>

            <div class="box-filters">
              <a class="btn-action"><i class="sy">-</i> Subcategoria</a>

              <b-form-group class="box-filters-group" v-slot="{ ariaDescribedby }">
                <b-form-checkbox-group
                  v-model="filtersForm.subcategories"
                  :aria-describedby="ariaDescribedby"
                  name="flavour-2">
                  <b-form-checkbox :value="subc.id" v-for="(subc,indx) in subcategoriesOpcs" :key="'subca'+indx">{{subc.name}}</b-form-checkbox>

                </b-form-checkbox-group>
              </b-form-group>
            </div>
            <div class="box-filters">
              <a class="btn-action"><i class="sy">-</i> Precio</a>

              <div class="box-filters-group">
                <b-form-select class="c-filter-select" v-model="filtersForm.price">
                  <b-form-select-option :value="null">Cualquiera</b-form-select-option>
                  <b-form-select-option :value="800">$800 o menos</b-form-select-option>
                  <b-form-select-option :value="1499">$1,499 o menos</b-form-select-option>
                  <b-form-select-option :value="999999999999999999">$1,500 o más</b-form-select-option>
                </b-form-select>
              </div>
            </div>

          </b-form>
        </div>
        <!--  -->

        <!-- Resultados en columnas -->
        <div class="col-lg">
          <div class="row sample-products-wrapper-v2">
            <div class="col-md-6 col-xl-4 sample-product-v2" v-for="(product, phInx) in products" :key="'phInx-'+phInx">
              <div class="outlined-box">
                <router-link class="fake-button" :to="'/producto/'+$root._converToURL(product.name, product.id)"></router-link>
                <div class="col box-image">
                  <!-- <div class="out-of-stock" v-if="product.stock < 1">Agotado</div> -->
                  <div class="inside">
                    <img :src="product.imageUrl" >
                  </div>
                </div>

                <div class="col box-descr">
                  <div class="b-brand">
                    <img :src="product.brandImageUrl">
                  </div>

                  <h6 class="name">{{ product.name }}</h6>
                  <h6 class="price">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(product.price) }}</h6>
                  <span class="iva">I.V.A. Incluido</span>
                  
                  <div class="b-stock">
                    <span>Existencias:</span>
                    <div class="num">+500</div>
                  </div>

                  <cartComponent :product="product"></cartComponent>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 text-center" v-if="!products.length && !isLoading">
              <h5>No se encontraron resultados</h5>
            </div>

            <div class="col-12" v-if="isLoading">
              <loading-component></loading-component>
            </div>
          </div>
        </div>
        <!--  -->

      </div>
    </section>

  </div>
</template>

<script>
import LoadingComponent from '../shared/loading-component.vue';
import cartComponent from './cart-component.vue';
export default {
  components: {
    LoadingComponent,
    cartComponent,
  },

  data(){
    return{
      isLoading: true,
      showFilters: false,

      filtersForm: {
        brands:[],
        subcategories:[],
        price:null,
      },

      products: [
        { id: 1, imageUrl: 'public/images/pages/products/product-2.jpg', brandImageUrl: 'public/images/pages/home/brands/thermaltake.png', name: 'Gabinete Thermaltake V5000 Tg Argb Air Black Color Negro', stock: 2, sku: '444', price: 850.50 },
        { id: 2, imageUrl: 'public/images/pages/products/product-1.jpg', brandImageUrl: 'public/images/pages/home/brands/thermaltake.png', name: 'Laptop Acer Aspire 3 15.6 Ryzen 7, 16gb/512gb, Windows 11 ', stock: 2, sku: '444', price: 10950.50 },
        { id: 3, imageUrl: 'public/images/pages/products/product-5.jpg', brandImageUrl: 'public/images/pages/home/brands/thermaltake.png', name: 'Combo Ryzen 5 5600g, Gigabyte B550m Ds3h Ac Motherboard 32gb', stock: 2, sku: '444', price: 4800.50 },
        { id: 4, imageUrl: 'public/images/pages/products/product-7.jpg', brandImageUrl: 'public/images/pages/home/brands/thermaltake.png', name: 'Gabinete para computadora de escritorio Asus TUF Gaming GT301 negro', stock: 2, sku: '444', price: 850.50 },
        { id: 5, imageUrl: 'public/images/pages/products/product-6.jpg', brandImageUrl: 'public/images/pages/home/brands/thermaltake.png', name: 'Laptop Touch Lenovo Slim 3 I3-1305u 8gb 512gb', stock: 2, sku: '444', price: 12000.50 },

        { id: 1, imageUrl: 'public/images/pages/products/product-2.jpg', brandImageUrl: 'public/images/pages/home/brands/thermaltake.png', name: 'Gabinete Thermaltake V5000 Tg Argb Air Black Color Negro', stock: 2, sku: '444', price: 850.50 },
        { id: 2, imageUrl: 'public/images/pages/products/product-1.jpg', brandImageUrl: 'public/images/pages/home/brands/thermaltake.png', name: 'Laptop Acer Aspire 3 15.6 Ryzen 7, 16gb/512gb, Windows 11 ', stock: 2, sku: '444', price: 10950.50 },
        { id: 3, imageUrl: 'public/images/pages/products/product-5.jpg', brandImageUrl: 'public/images/pages/home/brands/thermaltake.png', name: 'Combo Ryzen 5 5600g, Gigabyte B550m Ds3h Ac Motherboard 32gb', stock: 2, sku: '444', price: 4800.50 },
        { id: 4, imageUrl: 'public/images/pages/products/product-7.jpg', brandImageUrl: 'public/images/pages/home/brands/thermaltake.png', name: 'Gabinete para computadora de escritorio Asus TUF Gaming GT301 negro', stock: 2, sku: '444', price: 850.50 },
        { id: 5, imageUrl: 'public/images/pages/products/product-6.jpg', brandImageUrl: 'public/images/pages/home/brands/thermaltake.png', name: 'Laptop Touch Lenovo Slim 3 I3-1305u 8gb 512gb', stock: 2, sku: '444', price: 12000.50 },
        { id: 1, imageUrl: 'public/images/pages/products/product-2.jpg', brandImageUrl: 'public/images/pages/home/brands/thermaltake.png', name: 'Gabinete Thermaltake V5000 Tg Argb Air Black Color Negro', stock: 2, sku: '444', price: 850.50 },
        { id: 2, imageUrl: 'public/images/pages/products/product-1.jpg', brandImageUrl: 'public/images/pages/home/brands/thermaltake.png', name: 'Laptop Acer Aspire 3 15.6 Ryzen 7, 16gb/512gb, Windows 11 ', stock: 2, sku: '444', price: 10950.50 },
      ],

      subcategory: [],
      subcategory_id:null,
      brandsOpcs:[
        { id: 1, name: 'Marca 1' },
        { id: 2, name: 'Marca 2' },
        { id: 3, name: 'Marca 3' },
        { id: 4, name: 'Marca 4' },
        { id: 5, name: 'Marca 5' },
        { id: 6, name: 'Marca 6' },
      ],
      subcategoriesOpcs:[
        { id: 1, name: 'Subcategoría 1' },
        { id: 2, name: 'Subcategoría 2' },
        { id: 3, name: 'Subcategoría 3' },
        { id: 4, name: 'Subcategoría 4' },
      ],
    }
  },

  watch: {
    "$route.params.id" : function(v){
      this.subcategory_id = this.$root._getURLID(this.$route.params.id);
      if(this.subcategory_id){
          this.products=[];
          this.getProductsSubCategory(this.subcategory_id);
      }
    },
    "filtersForm.brands" : function(v){
        this.getProductsSubCategory(this.subcategory_id);
    },
    "filtersForm.subcategories" : function(v){
        this.getProductsSubCategory(this.subcategory_id);
    },
    "filtersForm.price" : function(v){
        this.getProductsSubCategory(this.subcategory_id);
    },
  },

  methods: {
    onSubmitFilters(evt) {
      evt.preventDefault();
    },

    getProducts: function(){
      axios.get(tools.url('/api/products')).then((response)=>{
        this.products = response.data;
      }).catch((error)=>{
        console.log(error);
      })
    },

    getProductsSubCategory: function(category_id){
      this.isLoading = true;
      axios.post(tools.url('/api/products/subcategory/'+category_id),this.filtersForm).then((response) =>{
          this.products = response.data.products;
          this.subcategory = response.data.subcategory;
          this.isLoading = false;
      }).catch((error)=>{
        console.log(error);
      });
    },
    getBrandsOpcs(){
      axios.get(tools.url('/api/brandsOpcs/'+this.subcategory_id+'/s')).then((response)=>{
        this.brandsOpcs = response.data;
      }).catch((error)=>{
        console.log(error);
      });
    },
    getSubcategoriesOpcs(){
      axios.get(tools.url('/api/subcategoriesOpcs/'+this.subcategory_id+'/s')).then((response)=>{
        this.subcategoriesOpcs = response.data;
      }).catch((error)=>{
        console.log(error);
      });
    },
  },

  mounted(){
    setTimeout( ()=>{ this.isLoading = false; }, 2000); // ejemplo de "loading" despues de cargar

    // if(this.$route.params.id){
    //    this.subcategory_id = this.$root._getURLID(this.$route.params.id);
    //    this.getProductsSubCategory(this.subcategory_id);
    //    this.getBrandsOpcs();
    //  this.getSubcategoriesOpcs();
    // }
  },
}
</script>
