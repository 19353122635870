<template lang="html">
  <footer id="footer">
    <section class="newsletter-section">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-7 col-xl-6 col-text">
            <h5><i class="fa-light fa-paper-plane-top icon"></i> ¡Recibe promociones exclusivas y descuentos!</h5>
          </div>

          <div class="col-lg-5 offset-xl-1 col-form">
            <b-form inline @submit="onSubmit">
              <b-form-input
                class=""
                required
                placeholder="Escribe tu correo"
              ></b-form-input>
              <b-button type="submit" class="btn-send">Suscribirse</b-button>
            </b-form>
          </div>
        </div>
      </div>
    </section>

    <section class="container oversized-container main-section">
      <div class="row">
        <div class="col-lg-3 col-xl-4 col-logo">
          <p>
            <router-link class="btn-logo" to="/">
              <img src="public/images/logo.png">
            </router-link>
          </p>

          <h6 class="txt-call">¿Necesitas ayuda? Llámanos</h6>
          <h5 class="txt-tel"><a href="tel:526144994221">+52 (614) 499 4221</a></h5>
        </div>

        <div class="col-lg-9 col-xl-8 col-info">
          <div class="row">
            <div class="col-6 col-lg-4 col-text">
              <h5 class="title">Mapa del sitio</h5>

              <p class="mb-1">
                <router-link to="">Promociones</router-link>
              </p>
              <p class="mb-1">
                <router-link to="">Lo más nuevo</router-link>
              </p>
              <p class="mb-1">
                <router-link to="">Iniciar sesión</router-link>
              </p>
              <p class="mb-1">
                <router-link to="">Registrarse</router-link>
              </p>
              <p>
                <router-link to="">Recuperar contraseña</router-link>
              </p>
            </div>

            <div class="col-6 col-lg-4 col-text">
              <h5 class="title">Sobre ICSI Tecno Store</h5>

              <p class="mb-1">
                <router-link to="">Inicio</router-link>
              </p>
              <p class="mb-1">
                <router-link to="">Sobre nosotros</router-link>
              </p>
              <p class="mb-1">
                <router-link to="">Preguntas frecuentes</router-link>
              </p>
              <p class="mb-1">
                <router-link to="">Contáctanos</router-link>
              </p>
              <p>
                <router-link to="">Aviso de privacidad</router-link>
              </p>
            </div>

            <div class="col-md-7 col-lg-4 col-img">
              <p>
                <img class="img-confianza" src="public/images/sello.webp">
              </p>
              <p class="pt-1 mt-2">
                <img class="img-estafeta" src="public/images/estafeta.png" alt="Estafeta">
              </p>

              <h6 class="address">
                <strong>Dirección: </strong>Prv. Río Bósforo No. 25922, Col. Riberas de Sacramento, C.P. 31184 Chihuahua, Chih México.
              </h6>

              <div class="box-networks">
                <a target="_blank" href="https://www.facebook.com/OficialTecnologiasicsi"><i class="fa-brands fa-facebook-f"></i></a>
                <a target="_blank" href="https://www.youtube.com/TecnologiasICSI"><i class="fa-brands fa-youtube"></i></a>
                <a target="_blank" href="https://twitter.com/tecnologiasicsi"><i class="fa-brands fa-x-twitter"></i></a>
                <a target="_blank" href="https://www.linkedin.com/in/tecnolog%C3%ADas-icsi-chihuahua-52b76147/"><i class="fa-brands fa-linkedin-in"></i></a>
                <a target="_blank" href="https://t.me/OficialTecnologiasICSI"><i class="fa-brands fa-telegram"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </footer>
</template>

<script>
export default {
  data(){
    return{
      currentYear: 0,

      form: {
        email: null
      },
      info: {
        email:null,
        contact:null,
        address:null,
      },
    }
  },

  methods:{

    makeToast(variant = null, msg, title) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-right',
        appendToast: true
      });
    },

    onSubmit(evt) {
      evt.preventDefault();
      var r = confirm("¿Deseas regístrate en newsletter? ");
      if (r == true) {
        axios.post(tools.url("/api/newsletter"), this.form).then((response)=>{
          this.makeToast('default', response.data.response, 'Correo registrado');
          this.form = {
            name: null,
            email: null,
            msg: null,
            check: null
          };
        }).catch( (error)=> {
          // console.log(error.response);
          let errors = error.response.data;
          // console.log(errors);
          this.makeToast('danger', error.response.data.name, 'Error');
          this.makeToast('danger', error.response.data.email, 'Error');
          this.makeToast('danger', error.response.data.phone, 'Error');
          this.makeToast('danger', error.response.data.msg, 'Error');
        });
      }
    },
    getInfo: function(){
      axios.get(tools.url('/api/info')).then((response)=>{
        this.info = response.data;
      }).catch((error)=>{
        console.log(error);
      })
    },
  },

  beforeMount(){
    this.currentYear = new Date().getFullYear();
    this.getInfo();
  }
}
</script>
