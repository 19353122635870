<template lang="html">
  <div id="description-page">
    <section class="breadcrumb-section">
      <div class="container oversized-container">
        <span>Producto</span>
        <span class="line">/</span>
        <span>{{ product.name }}</span>
      </div>
    </section>

    <section class="container oversized-container description-section" id="product-description">
      <div class="row">

        <div class="col-lg-6 col-gallery" v-if='!icecat'>
          <swiper :options="galleryOptions">
            <swiper-slide v-for="(row, galIndex) in gallery" :key="galIndex">
              <div class="image-container">
                <div class="img">
                  <v-zoom :img="row"></v-zoom>
                </div>
              </div>
            </swiper-slide>

            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>

        <b-form class="col-lg-6 col-description" @submit.prevent="quantityValidate()">
          <div class="bar-fav">
            <button v-if="favorite" type="button" class="btn-fav actived" @click="addFavorite()"><i class="fa-solid fa-heart"></i></button>
            <button v-if="!favorite" type="button" class="btn-fav" @click="addFavorite()"><i class="fa-light fa-heart"></i></button>
          </div>

          <h1 class="name">{{ product.name }}</h1>
          <!-- <h4 class="price discount"><span>$ {{ product.price }}</span></h4> -->
          <h4 class="price f-w-700">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(product.price) }}</h4>

          <p class="mt-2 extra-breadcrumb">
            <span><strong class="f-w-600">Modelo: </strong> {{ product.sku }}<br /></span>
          </p>
          <p class="mt-2 extra-breadcrumb">
            <span><strong class="f-w-600">Marca: </strong> Acer<br /></span>
          </p>
          <p class="mt-2 extra-breadcrumb">
            <span><strong class="f-w-600">Codigo SAT: </strong> 461780351<br /></span>
          </p>
          <p class="mt-2 extra-breadcrumb">
            <span v-if="product.category"><strong class="f-w-600">Categoria: </strong> <router-link class="buble" target="_blank" :to="'/categoria/'+$root._converToURL(product.category, product.category_id)">{{ product.category }}</router-link> <br /></span>
          </p>
          <p class="mt-2 extra-breadcrumb">
            <span v-if="product.subcategory"><strong class="f-w-600">Subcategoria: </strong> <router-link class="buble" target="_blank" :to="'/subcategoria/'+$root._converToURL(product.subcategory, product.subcategory_id)">{{ product.subcategory }}</router-link> <br /></span>
          </p>
          <p class="mt-2 extra-breadcrumb" v-if="product.brand">
            <span><strong class="f-w-600">Marca:</strong></span> <router-link class="buble" target="_blank"  :to="'/marca/'+$root._converToURL(product.marca, product.marca_id)"> {{ product.brand }}</router-link>
          </p>

          <h6 class="mt-3 subtitle">Descripción</h6>
          <div class="txt-description" v-html="product.description"></div>

          <!-- <div class="mt-1 extra-breadcrumb" v-if="product.ancho">Ancho: {{ product.ancho }} cm</div>
          <div class="mt-1 extra-breadcrumb" v-if="product.alto">Alto: {{ product.alto }} cm</div>
          <div class="mt-1 extra-breadcrumb" v-if="product.largo">Largo: {{ product.largo }} cm</div>
          <div class="mt-1 extra-breadcrumb" v-if="product.volumen">Volumen: {{ product.volumen }}</div>
          <div class="mt-1 extra-breadcrumb" v-if="product.peso">Peso: {{ product.peso }} kg</div>
          <div class="mt-1 extra-breadcrumb" v-if="product.material">Material: {{ product.material }}</div> -->

          <div class="mt-1 extra-breadcrumb">
            <span v-if="product.ancho"><strong>Ancho:</strong> {{ product.ancho }} cm. </span>
            <span v-if="product.alto"><strong>Alto:</strong> {{ product.alto }} cm. </span>
            <span v-if="product.largo"><strong>Largo:</strong> {{ product.largo }} cm. </span>
            <span v-if="product.volumen"><strong>Volumen:</strong> {{ product.volumen }}. </span>
            <span v-if="product.peso"><strong>Peso:</strong> {{ product.peso }} kg. </span>
            <span v-if="product.material"><strong>Material:</strong> {{ product.material }}.</span>
          </div>

          <h6 class="mt-2 subtitle" v-if="product.ficha">Ficha tecnica</h6>
          <div class="txt-description" v-if="product.ficha" v-html="product.ficha"></div>

          <h6 class="subtitle" v-if="product.color">Color</h6>
          <h4 class="price" v-if="product.color">{{ product.color }}</h4>
          <p class="mt-2 extra-breadcrumb" v-if="product.stock">
            <strong class="txt-blue f-w-700">Disponibles: {{ product.stock }}</strong>
          </p>
          <div class="out-of-stock" v-else style="width: 100px;padding: 4px 14px 5px 14px;color: #ffffff;font-size: 0.8rem;font-weight: 500;text-align: center;background-color: #f43c3c;">Agotado</div>

          <h6 class="mt-3 subtitle" v-if="product.stock">Cantidad</h6>
          <div class="row no-gutters pt-1 box-options" v-if="product.stock">
            <div class="col col-short">
              <b-form-input v-model="form.quantity" type="number" class="text-center" required></b-form-input>
            </div>
          </div>

          <div class="d-block mt-4 mb-2" v-if="product.pdf">
            <a class="btn btn-submit btn-contact" v-if="product.pdf" :href="product.pdf" target="_blank" rel="noopener noreferrer">PDF <i class="fa fa-file-pdf-o"></i></a>
          </div>

          <div class="col-12 my-2">
            <div class="row">
              <div class="col-sm-6 my-2 px-0 pr-sm-3" v-if="product.stock">
                <b-button type="submit" class="w-100 btn-submit">Agregar al carrito <i class="far fa-shopping-cart"></i></b-button>
              </div>

              <div class="col-sm-6 my-2 px-0 pl-sm-3">
                <router-link class="w-100 btn btn-submit btn-contact" :to="{ name: 'contacto', query: { p: product.name+', SKU: '+product.sku } }">Contactar <i class="far fa-envelope"></i></router-link>
              </div>
            </div>
          </div>

          <!-- <h6 class="subtitle">Inventarios</h6>
          <div class="col-12 mt-2 col-extra" id="col-extra">
            <table id="stocktable">
                <tr>
                  <th>Almacen</th>
                  <th>Stock</th>
                </tr>
                <tr v-for="(st,indx) in product.stocks" :key="indx">
                  <td>{{st.warehouse}}</td>
                  <td>{{st.stock}}</td>
                </tr>
            </table>
          </div> -->
          <!-- <h6 class="subtitle">Compartir</h6>
          <div class="col-12 mt-2 col-extra" id="col-extra">
            <div class="row">
              <div class="col b-print">
                <h6>Imprimir</h6>
                <div class="btn-q" @click="printDiv('product-description')"><span><i class="fas fa-print"></i></span></div>
              </div>

              <div class="col px-0 b-sharing">
                <ShareNetwork
                  v-for="(network, pdInx) in networks"
                  :network="network.network"
                  :key="network.network"
                  :style="{backgroundColor: network.color}"
                  :url="sharing.url"
                  :title="sharing.title"
                  :description="sharing.description"
                  :quote="sharing.quote"
                  :hashtags="sharing.hashtags"
                  :twitterUser="sharing.twitterUser">
                  <div class="btn-q"><span><i :class="network.icon"></i></span></div>
                </ShareNetwork>

              </div>
            </div>
          </div> -->
        </b-form>

        <div class="col-12 mb-2">
          <div class="col-12 px-0 col-s-title">
            <h2 class="title">Comentarios</h2>
            <hr class="mt-2 mb-0" />
          </div>
        </div>

        <div class="col-lg-6 col-comment-form">
          <div class="col-12 col-comments">
            <b-form class="box-comment" @submit="addComment">
              <h5 class="subt">Califica este producto</h5>

              <div class="box-stars">
                <b-form-rating inline required v-model="comment.stars" stars="5"></b-form-rating>
              </div>

              <div class="box-input">
                <b-form-textarea v-model="comment.body" placeholder="Escribe tu comentario" required rows="2" max-rows="2"></b-form-textarea>

                <p class="mt-2 text-right">
                  <b-button type="submit" class="btn-co">Comentar</b-button>
                </p>
              </div>
            </b-form>
          </div>
        </div>

        <div class="col-lg-6 mt-3 col-all-comments">
          <div class="wr-comments" v-if="comments.length">
            <div class="row">

              <div class="col-12 col-total-comments" v-if="avg > 0">
                <div class="box"><small>TOTAL:</small> {{ Math.ceil(avg) }}</div>
              </div>

              <div class="col-12 col-comments">
                <div class="row">
                  <div class="col-lg-6 col-comment" v-for="(item, coprInx) in comments" :key="'coprInx-'+coprInx">
                    <div class="box">
                      <div class="box-stars">
                        <b-form-rating v-model="item.star" readonly inline></b-form-rating>
                      </div>

                      <div class="box-comment">{{ item.comment }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-11 mx-auto col-extrainfo" v-if="icecat">
            <div id="Container5"></div>
            <div id="Container7"></div>
            <div id="Container11"></div>
        </div>

      </div>

    </section>

    <section class="container oversized-container related-products-section" id="related-products">
      <div class="row">
        <div class="col-12 px-0 col-s-title">
          <h2 class="title">Productos relacionados</h2>
          <hr class="mt-2 mb-2" />
        </div>
      </div>

      <div class="row">
        <div class="col-12 px-0 col-products">
          <swiper class="swiper carousel-t1 ct1-white" :options="productsOption">
            <swiper-slide v-for="(product, pInx) in products" :key="'pInx-'+pInx">
              <div class="col-12 px-0 sample-product-v2">
                <div class="outlined-box">
                  <router-link class="fake-button" :to="'/producto/'+$root._converToURL(product.name, product.id)"></router-link>
                  <div class="col box-image">
                    <!-- <div class="out-of-stock" v-if="product.stock < 1">Agotado</div> -->
                    <div class="inside">
                      <img :src="product.imageUrl" >
                    </div>
                  </div>

                  <div class="col box-descr">
                    <div class="b-brand">
                      <img :src="product.brandImageUrl">
                    </div>

                    <h6 class="name">{{ product.name }}</h6>
                    <h6 class="price">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(product.price) }}</h6>
                    <span class="iva">I.V.A. Incluido</span>

                    <div class="b-stock">
                      <span>Existencias:</span>
                      <div class="num">+500</div>
                    </div>

                    <cartComponent :product="product"></cartComponent>
                  </div>
                </div>
              </div>
            </swiper-slide>

            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>
      </div>
    </section>

    <section class="container oversized-container mt-2 related-products-section" id="related-products">
      <div class="row">
        <div class="col-12 px-0 col-s-title">
          <h2 class="title">Productos complementarios</h2>
          <hr class="mt-2 mb-2" />
        </div>
      </div>

      <div class="row">
        <div class="col-12 px-0 col-products">
          <swiper class="swiper carousel-t1 ct1-white" :options="productsOption">
            <swiper-slide v-for="(product, pInx) in products" :key="'pInx-'+pInx">
              <div class="col-12 px-0 sample-product-v2">
                <div class="outlined-box">
                  <router-link class="fake-button" :to="'/producto/'+$root._converToURL(product.name, product.id)"></router-link>
                  <div class="col box-image">
                    <!-- <div class="out-of-stock" v-if="product.stock < 1">Agotado</div> -->
                    <div class="inside">
                      <img :src="product.imageUrl" >
                    </div>
                  </div>

                  <div class="col box-descr">
                    <div class="b-brand">
                      <img :src="product.brandImageUrl">
                    </div>

                    <h6 class="name">{{ product.name }}</h6>
                    <h6 class="price">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(product.price) }}</h6>
                    <span class="iva">I.V.A. Incluido</span>

                    <div class="b-stock">
                      <span>Existencias:</span>
                      <div class="num">+500</div>
                    </div>

                    <cartComponent :product="product"></cartComponent>
                  </div>
                </div>
              </div>
            </swiper-slide>

            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>
      </div>
    </section>

    <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block"  ref="modal">
        <div v-html="modal.msg"></div>
        <div class="col-12 text-center" style="padding-top: 20px;" v-if="modal.icon == 'success'">
            <b-button class="btn btn-primary" slot="button" v-on:click.prevent="$refs.modal.close()">Agregar mas productos</b-button>
            <b-button class="btn btn-primary" slot="button" v-on:click.prevent="$refs.modal.close(); $router.push('/cart')">Ir al carrito <i class="color-white fas fa-shopping-cart"></i></b-button>
        </div>
    </sweet-modal>

  </div>
</template>

<script>
import vZoom from 'vue-zoom';
import cartComponent from './cart-component.vue';
export default {
  components: {
    vZoom,
    cartComponent
  },

  data() {
    return {
      id: null,

      form: {
        quantity: 1,
      },

      product: {
        id: 2,
        name: 'NOMBRE DEL PRODUCTO EN ESTA PÁGINA',
        sku: 'PMD5RAM',
        category_id: '5',
        category: 'Categoría 1',
        subcategory_id: '5',
        subcategory: 'Subcategoría 1',
        price: '350',
        marca_id: 1,
        marca: 'Marca Y',
        description: '<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>',
        stock: 35,
        ancho: 15,
        alto: 50,
        largo: 52,
        peso: 0.90,
        stocks:[],
      },
      category: [],
      products: [
        { id: 1, imageUrl: 'public/images/pages/products/product-2.jpg', brandImageUrl: 'public/images/pages/home/brands/thermaltake.png', name: 'Gabinete Thermaltake V5000 Tg Argb Air Black Color Negro', stock: 2, sku: '444', price: 850.50 },
        { id: 2, imageUrl: 'public/images/pages/products/product-1.jpg', brandImageUrl: 'public/images/pages/home/brands/thermaltake.png', name: 'Laptop Acer Aspire 3 15.6 Ryzen 7, 16gb/512gb, Windows 11 ', stock: 2, sku: '444', price: 10950.50 },
        { id: 3, imageUrl: 'public/images/pages/products/product-5.jpg', brandImageUrl: 'public/images/pages/home/brands/thermaltake.png', name: 'Combo Ryzen 5 5600g, Gigabyte B550m Ds3h Ac Motherboard 32gb', stock: 2, sku: '444', price: 4800.50 },
        { id: 4, imageUrl: 'public/images/pages/products/product-7.jpg', brandImageUrl: 'public/images/pages/home/brands/thermaltake.png', name: 'Gabinete para computadora de escritorio Asus TUF Gaming GT301 negro', stock: 2, sku: '444', price: 850.50 },
        { id: 5, imageUrl: 'public/images/pages/products/product-6.jpg', brandImageUrl: 'public/images/pages/home/brands/thermaltake.png', name: 'Laptop Touch Lenovo Slim 3 I3-1305u 8gb 512gb', stock: 2, sku: '444', price: 12000.50 },
      ],
      sizes: [],
      size: [],
      stock: 0,
      avg: 0,
      favorite: false,
      icecat: false,

      // == Sharing ==
      sharing: {
        url: 'https://icsi.store/#/producto/',
        title: 'Tecnologías ICSI',
        description: 'Esto es un ejemplo de descripción',
        quote: 'Esto es el Quote',
        hashtags: 'this,isthe,hashtag',
        twitterUser: 'twitter'
      },

      networks: [
        { network: 'twitter', name: 'Twitter', icon: 'fab fa-twitter' },
        { network: 'facebook', name: 'Facebook', icon: 'fab fa-facebook-f' },
        { network: 'pinterest', name: 'Pinterest', icon: 'fab fa-pinterest-p' },
      ],
      // == ==

      colors: [],

      gallery: [
        'public/images/pages/products/product-6.jpg',
        'public/images/pages/products/product-6.jpg',
      ],

      relatedProducts: [],

      comments: [],

      modal:{
        msg:'',
        icon:'',
        block:false
      },

      comment:{
        stars: null,
        body: ''
      },

      // == Carrusel options ==
      galleryOptions: {
        loop: false,
        speed: 600,

        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },

        // autoplay: {
        //   delay: 3000,
        //   disableOnInteraction: false
        // }
      },

      productsOption: {
        loop: false,
        slidesPerView: 4,
        spaceBetween: 10,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        breakpoints: {
          1200: {
            slidesPerView: 4
          },
          992: {
            spaceBetween: 10,
            slidesPerView: 3
          },
          768: {
            spaceBetween: 20,
            slidesPerView: 2
          },
          1: {
            slidesPerView: 1
          },
        }
      },
      // == ==
    }
  },

  watch: {
    "$route.params.id" : function(v){
      //this.id = this.$route.params.id;
      this.id = this.$root._getURLID(this.$route.params.id);
      this.getProduct();
    }
  },

  methods:{
    toast(title, msg, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        toaster: 'b-toaster-bottom-right',
        variant: variant,
        solid: true,
        appendToast: true
      })
    },

    getComments(id){
      axios.get(tools.url('/api/comments/'+id)).then((response)=>{
        this.comments = response.data.comments;
        this.avg = response.data.avg;

      }).catch((error)=>{
        console.log(error);
      })
    },

    addComment: function(){
        axios.post(tools.url("/api/comments/"+this.product.id),{stars:this.comment.stars, comment:this.comment.body}).then((response) => {
            this.comment.stars = null;
            this.comment.body = '';
            this.getComments(this.id);
        }).catch((error) => {
            alert("Error al agregar comentario");
            console.log(error);
        })
    },

    quantityValidate(){
        if(this.form.quantity > this.product.stock){
            if(this.product.stock <= 1){ w = 'pieza' };
            this.toast('Error', 'Este artículo cuenta con maximo '+this.product.stock+' '+w+' en existencia', 'danger');
        }else{
            this.stock = this.product.stock;
            this.addCart();
        }
    },

    getProduct(){
      axios.get(tools.url('/api/products/' + this.id)).then((response)=>{
        this.product = [];
        this.gallery = [];
        this.product = response.data;
        this.category = response.data.category;
        this.gallery = response.data.gallery;

        if(this.product.stock > 0){
          this.form.quantity = 1;
        }

        this.getRelated(this.product.id);
        //this.getComments(this.product.id);
      }).catch((error)=>{
        console.log(error);
      });
    },

    addCart(){
      var cart = [];
      var encontrado = false;

      if(this.form.quantity > 0){
          if(localStorage.cart){
            cart = JSON.parse(localStorage.getItem('cart'));

            for(var i = 0; i < cart.length ; i++){
              if(cart[i].id == this.id){
                var suma = Number(cart[i].quantity) + Number(this.form.quantity);
                cart[i].quantity = suma <= this.stock ? suma : this.stock;
                encontrado = true;
                break;
              }
            }

            if(!encontrado){
              cart.push({ id:this.id, quantity:this.form.quantity });
            }
          } else{
            cart.push({ id:this.id, quantity:this.form.quantity });
          }

          localStorage.setItem("cart",JSON.stringify(cart));
          this.$root.cartCount = cart.length;
          this.modal.msg = 'El producto <b>'+this.product.name+'</b> se agregado al carrito con '+this.form.quantity+' piezas';
          this.modal.icon = 'success';
          this.$refs.modal.open();
      }

    },

    getRelated(id){
      axios.get(tools.url('/api/products/related/'+id)).then((response)=>{
        this.products = response.data;
      }).catch((error)=>{
        console.log(error);
      })
    },

    getFavorite: function(){
        if(this.$root.user.id){
            axios.post(tools.url('/api/favorite'),{ product_id: this.id }).then((response)=>{
                if(response.data == 'true'){
                    this.favorite = true;
                }else{
                    this.favorite = false;
                }
                this.favoritesCount();
            }).catch((error)=>{
                console.log(error);
            });
        }
    },

    addFavorite: function(){
      this.favorite = !this.favorite;
        // if(this.$root.user.id){
        //     axios.post(tools.url('/api/addFavorite'),{product_id: this.id }).then((response)=>{
        //         this.favorite = response.data.favorite;
        //         this.favoritesCount();
        //     }).catch((error)=>{
        //         console.log(error);
        //     });
        // }
    },

    favoritesCount: function(){
        if(this.$root.user.id){
            axios.get(tools.url('/api/favoritesCount')).then((response)=>{
                this.$root.favoriteCount = response.data;
            }).catch((error)=>{
                console.log(error);
            })
        }
    }

  },

   mounted(){
    this.id = this.$route.params.id;
    this.id = this.$root._getURLID(this.$route.params.id);
    this.getProduct();

    /*if(this.$root.logged == true){
        this.getFavorite();
    }*/
  }
}
</script>
<style>
#stocktable table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#stocktable td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
#stocktable th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  background-color: #dddddd;
}
</style>
