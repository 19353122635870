<template lang="html">
  <header id="header">
    <div class="header-content">

      <!-- Top menu -->
      <div class="box-top-menu">
        <div class="container d-flex align-items-center oversized-container">
          <div class="col col-lg-3 col-xl col-ml">
            <h5>Compra de forma segura</h5>
          </div>
          <div class="col col-lg-3 col-xl col-ssl">
            <img src="public/images/shared/ssl1.png">
            <span>Sitio seguro encriptado</span>
          </div>
          <div class="col-lg-7 col-xl-8 ml-auto col-right">
            <span class="txt-content">
              <router-link to="">Rastréa tu pedido</router-link>
              <router-link to="">Facturación</router-link>
              <span class="d-lg-none d-xl-inline-block">Formas de pago</span>
            </span>
            <!-- <i class="fab fa-cc-mastercard icon"></i>
            <i class="fab fa-cc-visa icon"></i>
            <i class="fab fa-cc-amex icon"></i> -->
            <img class="img-icons" src="public/images/payments.svg">
          </div>
        </div>
      </div>
      <!--  -->

      <!-- Main menu -->
      <div class="box-main-menu">
        <b-navbar toggleable="lg" type="light" variant="light">
          <div class="container oversized-container">
            <b-navbar-brand to="/">
              <img src="public/images/logo.png">
            </b-navbar-brand>

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
              <b-navbar-nav class="mx-auto">
                <b-nav-form class="box-search" @submit="onSubmitSearch">
                  <b-form-input required placeholder="Buscar"></b-form-input>
                  <b-button class="btn-search" type="submit"><i class="fa-light fa-magnifying-glass"></i></b-button>
                </b-nav-form>
              </b-navbar-nav>

              <b-navbar-nav class="ml-auto">
                <b-nav-item class="d-lg-none cart-item icon-item" to="/">Inicio</b-nav-item>
                <li class="nav-item b-nav-dropdown dropdown">
                  <a class="nav-link dropdown-toggle" @click="isHActive = !isHActive">Categorías</a>
                </li>
                <b-nav-item class="d-lg-none" to="/categoria/ejemplo-de-categoria-1">Promociones</b-nav-item>
                <b-nav-item class="d-lg-none" to="/categoria/ejemplo-de-categoria-1">Lo más nuevo</b-nav-item>
                <b-nav-item class="d-lg-none" to="/preguntas-frecuentes">Preguntas frecuentes</b-nav-item>
                <b-nav-item class="d-lg-none" to="/nosotros">Nosotros</b-nav-item>
                <b-nav-item class="d-lg-none" to="/contacto">Contáctanos</b-nav-item>

                <b-nav-item-dropdown class="icon-item mr-0 mr-lg-4" right>
                  <template #button-content>
                    <span><i class="fa-solid fa-user-large icon"></i> Mi cuenta</span>
                  </template>
                  <b-dropdown-item to="">Iniciar sesión</b-dropdown-item>
                  <b-dropdown-item to="">Registrarse</b-dropdown-item>
                </b-nav-item-dropdown>

                <b-nav-item class="cart-item icon-item" to="/cart">
                  <div class="ic-cart">
                    <i class="fas fa-shopping-cart icon"></i>
                    <span class="num">1</span>
                  </div>
                  <span class="txt">$626.00</span>
                </b-nav-item>
              </b-navbar-nav>
            </b-collapse>
          </div>
        </b-navbar>
      </div>
      <!--  -->

      <!-- Header bottom -->
      <div class="header-bottom">
        <b-navbar toggleable="lg" type="dark" variant="info">
          <div class="container oversized-container px-1">
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
              <b-navbar-nav class="mx-auto">
                <li class="nav-item b-nav-dropdown dropdown">
                  <a class="nav-link dropdown-toggle" @click="isHActive = !isHActive">Categorías</a>
                </li>

                <!-- <b-nav-item to="/novedades">Novedades</b-nav-item> -->
                <b-nav-item to="/categoria/ejemplo-de-categoria-1">Promociones</b-nav-item>
                <b-nav-item to="/categoria/ejemplo-de-categoria-1">Lo más nuevo</b-nav-item>
                <b-nav-item to="/preguntas-frecuentes">Preguntas frecuentes</b-nav-item>
                <b-nav-item to="/nosotros">Nosotros</b-nav-item>
                <b-nav-item to="/contacto">Contáctanos</b-nav-item>
              </b-navbar-nav>
            </b-collapse>
          </div>
        </b-navbar>
      </div>
      <!--  -->

    </div>

    <!-- Categorías -->
    <div class="t-250 products-menu" v-bind:class="{ active: isHActive }">
      <div class="menu-bg" @click="isHActive = false"></div>

      <div class="tablist" role="tablist">
        <div class="t-250 box-title" @click="isHActive = false">
          <h4>CATEGORÍAS</h4>
          <!-- <br>
          <h5 v-on:click="$router.push('/productos');">Categorias</h5> -->
          <!-- <i class="fas fa-chevron-left icon"></i> -->
          <i class="fas fa-chevron-square-left icon"></i>
        </div>

        <b-card no-body class="mb-1" v-for="(category, pcatIndex) in categories" :key="pcatIndex">
          <b-card-header header-tag="header" role="tab">
            <div v-if="category.subcategories" class="btn-toggle">
              <router-link :to="'/categoria/'+$root._converToURL(category.name, category.id)">{{ category.name }}</router-link>
              <span class="fake-toggle" v-b-toggle="'accordion-'+pcatIndex"></span>
            </div>
            <router-link v-else class="btn-toggle" :to="'/categoria/'+$root._converToURL(category.name, category.id)">{{ category.name }}</router-link>
          </b-card-header>

          <b-collapse v-if="category.subcategories" :id="'accordion-'+pcatIndex" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <ul class="ul-sub-options">
                <li v-for="subcategory in category.subcategories">
                  <router-link class="t-250" :to="'/subcategoria/'+$root._converToURL(subcategory.name, subcategory.id)">{{ subcategory.name }}</router-link>
                </li>
              </ul>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>
    <!--  -->

  </header>
</template>

<script>
export default {
  data(){
    return{
      isHActive: false,

      categories:[ // ** Remover el contenido de este array ejemplo al conectar para no dejar CODIGO BASURA
        { id: 1, name: 'Ejemplo de categoría' },
        { id: 2, name: 'Ejemplo de categoría', subcategories: [
          { id: 1, name: 'Ejemplo de subcategoría' },
          { id: 2, name: 'Ejemplo de subcategoría' },
        ] },
        { id: 3, name: 'Ejemplo de categoría' },
        { id: 4, name: 'Ejemplo de categoría', subcategories: [
          { id: 1, name: 'Ejemplo de subcategoría' },
          { id: 2, name: 'Ejemplo de subcategoría' },
        ]},
      ],

      formSearch: {
        keywords: null,
        category: null
      },

    }
  },

  methods: {
      onSubmitSearch(event) {
        event.preventDefault();

        this.$router.push({path: '/busqueda', query: {keywords:this.formSearch.keywords,category:this.formSearch.category}});
      },
      getCategories(){
				axios.get(tools.url("/api/categories")).then((response)=>{
            this.categories = response.data;
			    	this.$parent.inPetition=false;
			    }).catch((error)=>{
			    });
			},
  },

  watch: {
    $route (to, from){
      // Ocultar menu cada vez que cambie la ruta
      this.isHActive = false;
    }
  },
  mounted(){
      this.getCategories();
  }
}
</script>
