<template lang="html">
  <div>
    <section class="brands-section-ab bg-gray">
      <div class="container">
        <div class="row">
          <div class="col-12 col-tile">
            <h2>Nuestros clientes actuales</h2>
            <p>
              Estamos orgullosos de las empresas con las que trabajamos. Sé parte de nuestros clientes satisfechos.
            </p>
          </div>

          <div class="col-12 box-brands-v1">
            <div class="col-12 col-brands">
              <swiper class="swiper" :options="brandsOptions">
                <swiper-slide v-for="(brand, brhInx) in brandsTemp" :key="'brhInx-'+brhInx">
                  <div class="box-brand">
                    <a>
                      <div class="inside">
                        <img :src="brand.imageUrl">
                      </div>
                    </a>
                  </div>
                </swiper-slide>

                <div class="swiper-pagination" slot="pagination"></div>
              </swiper>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      brandsCat: [
        { title: 'Ciberseguridad', logos: [
            { imageUrl: 'public/images/pages/home/brands/fortinet.png' },
            { imageUrl: 'public/images/pages/home/brands/kaspersky.png' },
            { imageUrl: 'public/images/pages/home/brands/sonicwall.png' },
            { imageUrl: 'public/images/pages/home/brands/barracuda.png' },
          ],
        },
        { title: 'Redes Empresariales', logos: [
            { imageUrl: 'public/images/pages/home/brands/cisco.png' },
            { imageUrl: 'public/images/pages/home/brands/radwin.png' },
            { imageUrl: 'public/images/pages/home/brands/rockus.png' },
            { imageUrl: 'public/images/pages/home/brands/commscope.png' },
          ],
        },
        { title: 'Soliciones Industriales', logos: [
            { imageUrl: 'public/images/pages/home/brands/cisco.png' },
            { imageUrl: 'public/images/pages/home/brands/planet.png' },
          ],
        },
        { title: 'Respaldos', logos: [
            { imageUrl: 'public/images/pages/home/brands/synology.png' },
            { imageUrl: 'public/images/pages/home/brands/veeam.png' },
            { imageUrl: 'public/images/pages/home/brands/buffalo.png' },
          ],
        },
        { title: 'Infraestructura', logos: [
            { imageUrl: 'public/images/pages/home/brands/apc.png' },
            { imageUrl: 'public/images/pages/home/brands/veeam.png' },
            { imageUrl: 'public/images/pages/home/brands/linkedpro.png' },
            { imageUrl: 'public/images/pages/home/brands/panduit.png' },
            { imageUrl: 'public/images/pages/home/brands/belden.png' },
            { imageUrl: 'public/images/pages/home/brands/cyberpower.png' },
            { imageUrl: 'public/images/pages/home/brands/thorsman.png' },
          ],
        },
        { title: 'Cómputo', logos: [
            { imageUrl: 'public/images/pages/home/brands/delltech.png' },
            { imageUrl: 'public/images/pages/home/brands/lenovo.png' },
            { imageUrl: 'public/images/pages/home/brands/dell.png' },
            { imageUrl: 'public/images/pages/home/brands/hp.png' },
            { imageUrl: 'public/images/pages/home/brands/apple.png' },
            { imageUrl: 'public/images/pages/home/brands/nutanix.png' },
          ],
        },
        { title: 'Softwate y licenciamiento', logos: [
            { imageUrl: 'public/images/pages/home/brands/microsoft.png' },
            { imageUrl: 'public/images/pages/home/brands/office365.png' },
            { imageUrl: 'public/images/pages/home/brands/autodesk.png' },
            { imageUrl: 'public/images/pages/home/brands/mcafee.png' },
            { imageUrl: 'public/images/pages/home/brands/adobe.png' },
            { imageUrl: 'public/images/pages/home/brands/kaspersky.png' },
          ],
        },
        { title: 'Soluciones en la nube', logos: [
            { imageUrl: 'public/images/pages/home/brands/azure.png' },
            { imageUrl: 'public/images/pages/home/brands/aws.png' },
            { imageUrl: 'public/images/pages/home/brands/office365.png' },
            { imageUrl: 'public/images/pages/home/brands/veeam.png' },
          ],
        },
        { title: 'Comunicación y Videovigilancia', logos: [
            { imageUrl: 'public/images/pages/home/brands/epcom.png' },
            { imageUrl: 'public/images/pages/home/brands/polycom.png' },
            { imageUrl: 'public/images/pages/home/brands/grandstream.png' },
            { imageUrl: 'public/images/pages/home/brands/hikvision.png' },
            { imageUrl: 'public/images/pages/home/brands/ubiquiti.png' },
            { imageUrl: 'public/images/pages/home/brands/dahua.png' },
            { imageUrl: 'public/images/pages/home/brands/provisionisr.png' },
          ],
        },
        { title: 'Comunicaciones Unificadas Cloud', logos: [
            { imageUrl: 'public/images/pages/home/brands/huaweicloud.png' },
            { imageUrl: 'public/images/pages/home/brands/plantronics.png' },
            { imageUrl: 'public/images/pages/home/brands/poly.png' },
            { imageUrl: 'public/images/pages/home/brands/3cx.png' },
            { imageUrl: 'public/images/pages/home/brands/fanvil.png' },
            { imageUrl: 'public/images/pages/home/brands/jabragn.png' },
          ],
        },
        { title: 'Centro de Datos', logos: [
            { imageUrl: 'public/images/pages/home/brands/lenovo.png' },
            { imageUrl: 'public/images/pages/home/brands/dellemc.png' },
            { imageUrl: 'public/images/pages/home/brands/cisco.png' },
            { imageUrl: 'public/images/pages/home/brands/hewlett-packard-enterprise.png' },
            { imageUrl: 'public/images/pages/home/brands/belden.png' },
            { imageUrl: 'public/images/pages/home/brands/tripplite.png' },
            { imageUrl: 'public/images/pages/home/brands/panduit.png' },
          ],
        },
        { title: 'Radiocomunicaciones', logos: [
            { imageUrl: 'public/images/pages/home/brands/kenwood.png' },
            { imageUrl: 'public/images/pages/home/brands/txpro.png' },
            { imageUrl: 'public/images/pages/home/brands/hytera.png' },
            { imageUrl: 'public/images/pages/home/brands/motorolasolutions.png' },
            { imageUrl: 'public/images/pages/home/brands/icom.png' },
          ],
        },
        { title: 'Paneles solares', logos: [
            { imageUrl: 'public/images/pages/home/brands/canadiansolar.png', w: 22 },
            { imageUrl: 'public/images/pages/home/brands/growatt.png' },
          ],
        },
      ],

      brands: [],
      brandsTemp: [
        { id: 1, imageUrl: 'public/images/pages/home/brands/camionerachihuahua.png' },
        { id: 2, imageUrl: 'public/images/pages/home/brands/algodonera.png' },
        { id: 3, imageUrl: 'public/images/pages/home/brands/mining.png' },
        { id: 4, imageUrl: 'public/images/pages/home/brands/colegio.png' },
        { id: 5, imageUrl: 'public/images/pages/home/brands/promoespacio.png' },
        { id: 6, imageUrl: 'public/images/pages/home/brands/elektra.png' },
        { id: 7, imageUrl: 'public/images/pages/home/brands/bancoazteca.png' },
        { id: 7, imageUrl: 'public/images/pages/home/brands/starbucks.png' },
        { id: 7, imageUrl: 'public/images/pages/home/brands/obras.png' },
        { id: 7, imageUrl: 'public/images/pages/home/brands/berel.png' },
        { id: 7, imageUrl: 'public/images/pages/home/brands/minacerro.png' },
        { id: 7, imageUrl: 'public/images/pages/home/brands/diim.png' },
        { id: 7, imageUrl: 'public/images/pages/home/brands/fruver.png' },
        { id: 7, imageUrl: 'public/images/pages/home/brands/gpaaero.png' },
      ],

      /* == Carousel options == */
      brandsOptions: {
        loop: true,
        slidesPerView: 4,
        spaceBetween: 35,

        autoplay: {
          delay: 4500,
          disableOnInteraction: false
        },

        breakpoints: {
          1300: {
            slidesPerView: 4,
          },
          992: {
            slidesPerView: 4,
          },
          767: {
            slidesPerView: 3,
          },
          470: {
            slidesPerView: 2,
          },
          0: {
            slidesPerView: 1,
          },
        }
      },

      brandsOptionsv2: {
        loop: true,
        speed: 700,

        autoplay: {
          delay: 4500,
          disableOnInteraction: false
        },
      }
      // == ==
    }
  }
}
</script>
