<template lang="html">
  <div id="home-page">

    <!-- Banners  -->
    <section class="container-fluid banner-section">
      <swiper class="swiper-desktop" :options="bannersOption">
        <swiper-slide v-for="(banner, index) in banners.pc" :key="index">
          <!-- <a class="placed-backg" :href="banner.url ?  banner.url : '#'" v-bind:style="{ backgroundImage: 'url('+banner.imageUrl+')' }">
            <img src="public/images/pages/home/banner.png">
          </a> -->
          <a class="placed-backg" href="" v-bind:style="{ backgroundImage: 'url(public/images/pages/home/banner-1.jpg)' }">
            <img src="public/images/pages/home/banner.png">
          </a>
        </swiper-slide>

        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>

      <swiper class="swiper-mobile" :options="bannersOption">
        <swiper-slide  v-for="(banner, index) in banners.movil" :key="index">
           <!-- <a class="placed-backg" :href="banner.url ?  banner.url : '#'" v-bind:style="{ backgroundImage: 'url('+banner.imageUrl+')' }">
             <img src="public/images/pages/home/banner-m.png">
           </a> -->
           <a class="placed-backg" href="" v-bind:style="{ backgroundImage: 'url(public/images/pages/home/banner-1-m.jpg)' }">
             <img src="public/images/pages/home/banner-m.png">
           </a>
        </swiper-slide>

        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </section>
    <!--  -->

    <!-- Short info -->
    <section class="container-fluid short-info-section">
      <div class="row">
        <div class="col-lg col-info">
          <div class="inside">
            <div class="col col-icon">
              <img src="public/images/pages/home/shipping.png">
            </div>

            <div class="col col-text">
              <h6><strong>Envíos</strong><br />a todo México</h6>
            </div>
          </div>
        </div>

        <div class="col-lg col-info">
          <div class="inside">
            <div class="col col-icon">
              <img src="public/images/pages/home/safe-purchase.png">
            </div>

            <div class="col col-text">
              <h6><strong>Compra</strong><br />100% segura</h6>
            </div>
          </div>
        </div>

        <div class="col-lg col-info">
          <div class="inside">
            <div class="col col-icon">
              <img src="public/images/pages/home/the-best.png">
            </div>

            <div class="col col-text">
              <h6><strong>Las mejores</strong><br />marcas</h6>
            </div>
          </div>
        </div>

        <div class="col-lg col-info">
          <div class="inside">
            <div class="col col-icon">
              <img src="public/images/pages/home/promotions.png">
            </div>

            <div class="col col-text">
              <h6><strong>Promociones</strong><br />exclusivas</h6>
            </div>
          </div>
        </div>

        <div class="col-lg col-info">
          <div class="inside">
            <div class="col col-icon">
              <img src="public/images/pages/home/payments.png">
            </div>

            <div class="col col-text">
              <h6><strong>Elije la mejor</strong><br />forma de pago</h6>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--  -->

    <!-- Productos | Lo + visto -->
    <section class="container oversized-container mb-4 products-section">
      <div class="row">
        <div class="col-12 col-title-s2">
          <h2 class="title">Lo + visto</h2>
        </div>
      </div>

      <div class="row">
        <div class="col-12 px-0 col-products">
          <swiper class="swiper carousel-t1 ct1-white" :options="productsOption">
            <swiper-slide v-for="(product, pvInx) in products_popular" :key="'pvInx-'+pvInx">
              <div class="col-12 px-0 sample-product-v2">
                <div class="outlined-box no-b">
                  <router-link class="fake-button" :to="'/producto/'+$root._converToURL(product.name, product.id)"></router-link>
                  <div class="col box-image">
                    <!-- <div class="out-of-stock" v-if="product.stock < 1">Agotado</div> -->
                    <div class="inside">
                      <img :src="product.imageUrl" >
                    </div>
                  </div>

                  <div class="col box-descr">
                    <div class="b-brand">
                      <img :src="product.brandImageUrl">
                    </div>

                    <h6 class="name">{{ product.name }}</h6>
                    <h6 class="price">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(product.price) }}</h6>
                    <span class="iva">I.V.A. Incluido</span>

                    <div class="b-stock">
                      <span>Existencias:</span>
                      <div class="num">+500</div>
                    </div>

                    <cartComponent :product="product"></cartComponent>
                  </div>
                </div>
              </div>
            </swiper-slide>

            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>
      </div>
    </section>
    <!--  -->

    <!-- Productos | Recien agregados -->
    <section class="container oversized-container mb-4 products-section">
      <div class="row">
        <div class="col-12 col-title-s2">
          <h2 class="title">Recien agregados</h2>
        </div>
      </div>

      <div class="row">
        <div class="col-12 px-0 col-products">
          <swiper class="swiper carousel-t1 ct1-white" :options="productsOption">
            <swiper-slide v-for="(product, pvInx) in products_new" :key="'pvInx-'+pvInx">
              <div class="col-12 px-0 sample-product-v2">
                <div class="outlined-box no-b">
                  <router-link class="fake-button" :to="'/producto/'+$root._converToURL(product.name, product.id)"></router-link>
                  <div class="col box-image">
                    <!-- <div class="out-of-stock" v-if="product.stock < 1">Agotado</div> -->
                    <div class="inside">
                      <img :src="product.imageUrl" >
                    </div>
                  </div>

                  <div class="col box-descr">
                    <div class="b-brand">
                      <img :src="product.brandImageUrl">
                    </div>

                    <h6 class="name">{{ product.name }}</h6>
                    <h6 class="price">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(product.price) }}</h6>
                    <span class="iva">I.V.A. Incluido</span>

                    <div class="b-stock">
                      <span>Existencias:</span>
                      <div class="num">+500</div>
                    </div>

                    <cartComponent :product="product"></cartComponent>
                  </div>
                </div>
              </div>
            </swiper-slide>

            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>
      </div>
    </section>
    <!--  -->

    <!-- Banner extra -->
    <section class="banners-s2-section">
      <router-link to="">
        <!-- <img class="img-des" :src="banner_bottom['pc'][0]['imageUrl']">
        <img class="img-mob" :src="banner_bottom['movil'][0]['imageUrl']"> -->
        <img class="img-des" src="public/images/pages/home/banner-s2-1.jpg">
        <img class="img-mob" src="public/images/pages/home/banner-s2-1-m.jpg">
      </router-link>
    </section>
    <!--  -->

    <!-- Marcas -->
    <section class="brands-section-ab bg-gray">
      <div class="container">
        <div class="row">
          <div class="col-12 col-title-s2">
            <h2 class="title">Recien agregados</h2>
          </div>

          <div class="col-12 box-brands-v1">
            <div class="col-12 col-brands">
              <swiper class="swiper" :options="brandsOptions">
                <swiper-slide v-for="(brand, brhInx) in brandsTemp" :key="'brhInx-'+brhInx">
                  <div class="box-brand">
                    <a>
                      <div class="inside">
                        <img :src="brand.imageUrl">
                      </div>
                    </a>
                  </div>
                </swiper-slide>

                <div class="swiper-pagination" slot="pagination"></div>
              </swiper>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--  -->

    <!-- Productos | Populares -->
    <section class="container oversized-container mb-4 products-section">
      <div class="row">
        <div class="col-12 col-title-s2">
          <h2 class="title">Lo más vendido</h2>
        </div>
      </div>

      <div class="row">
        <div class="col-12 px-0 col-products">
          <swiper class="swiper carousel-t1 ct1-white" :options="productsOption">
            <swiper-slide v-for="(product, phInx) in products_popular" :key="'phInx-'+phInx">
              <div class="col-12 px-0 sample-product-v2">
                <div class="outlined-box no-b">
                  <router-link class="fake-button" :to="'/producto/'+$root._converToURL(product.name, product.id)"></router-link>
                  <div class="col box-image">
                    <!-- <div class="out-of-stock" v-if="product.stock < 1">Agotado</div> -->
                    <div class="inside">
                      <img :src="product.imageUrl" >
                    </div>
                  </div>

                  <div class="col box-descr">
                    <div class="b-brand">
                      <img :src="product.brandImageUrl">
                    </div>

                    <h6 class="name">{{ product.name }}</h6>
                    <h6 class="price">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(product.price) }}</h6>
                    <span class="iva">I.V.A. Incluido</span>

                    <div class="b-stock">
                      <span>Existencias:</span>
                      <div class="num">+500</div>
                    </div>

                    <cartComponent :product="product"></cartComponent>
                  </div>
                </div>
              </div>
            </swiper-slide>

            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>
      </div>
    </section>
    <!--  -->

    <!-- Productos | Seguridad -->
    <section class="container oversized-container mb-4 products-section">
      <div class="row">
        <div class="col-12 col-title-s2">
          <h2 class="title">Seguridad</h2>
        </div>
      </div>

      <div class="row">
        <div class="col-12 px-0 col-products">
          <swiper class="swiper carousel-t1 ct1-white" :options="productsOption">
            <swiper-slide v-for="(product, phInx) in products_security" :key="'phInx-'+phInx">
              <div class="col-12 px-0 sample-product-v2">
                <div class="outlined-box no-b">
                  <router-link class="fake-button" :to="'/producto/'+$root._converToURL(product.name, product.id)"></router-link>
                  <div class="col box-image">
                    <!-- <div class="out-of-stock" v-if="product.stock < 1">Agotado</div> -->
                    <div class="inside">
                      <img :src="product.imageUrl" >
                    </div>
                  </div>

                  <div class="col box-descr">
                    <div class="b-brand">
                      <img :src="product.brandImageUrl">
                    </div>

                    <h6 class="name">{{ product.name }}</h6>
                    <h6 class="price">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(product.price) }}</h6>
                    <span class="iva">I.V.A. Incluido</span>
                    
                    <div class="b-stock">
                      <span>Existencias:</span>
                      <div class="num">+500</div>
                    </div>

                    <cartComponent :product="product"></cartComponent>
                  </div>
                </div>
              </div>
            </swiper-slide>

            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>
      </div>
    </section>
    <!--  -->

    <!-- Extra mini banners -->
    <section class="container oversized-container extra-mini-banners">
      <div class="row">
        <div class="col-lg-4 col-banner">
          <router-link class="placed-backg" v-bind:style="{ backgroundImage: 'url(public/images/pages/home/mbanner-2.jpg)' }" to="">
            <img src="public/images/pages/home/mbanner-sm.png">
          </router-link>
        </div>

        <div class="col-lg-8 col-banner">
          <router-link class="placed-backg" v-bind:style="{ backgroundImage: 'url(public/images/pages/home/mbanner-1.jpg)' }" to="">
            <img src="public/images/pages/home/mbanner-lg.png">
          </router-link>
        </div>

        <div class="col-lg-8 col-banner">
          <router-link class="placed-backg" v-bind:style="{ backgroundImage: 'url(public/images/pages/home/mbanner-4.jpg)' }" to="">
            <img src="public/images/pages/home/mbanner-lg.png">
          </router-link>
        </div>

        <div class="col-lg-4 col-banner">
          <router-link class="placed-backg" v-bind:style="{ backgroundImage: 'url(public/images/pages/home/mbanner-3.jpg)' }" to="">
            <img src="public/images/pages/home/mbanner-sm.png">
          </router-link>
        </div>

        <div class="col-lg-12 col-banner">
          <router-link class="placed-backg" to="">
            <img src="public/images/pages/home/mbanner-5.jpg">
          </router-link>
        </div>
      </div>
    </section>
    <!--  -->

    <!-- Multibanners -->
    <!-- <section class="container oversized-container multi-banners-section">
      <swiper class="swiper" :options="multibanersOptions">
        <swiper-slide v-for="(banner, bxhInx) in banner_middle" :key="'bxhInx-'+bxhInx">
          <div class="col-12 px-0 col-banner">
            <a :href="banner.url">
              <img :src="banner.imageUrl">
            </a>
          </div>
        </swiper-slide>

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </section> -->
    <!--  -->

    <!-- Blog -->
    <!-- <section class="container oversized-container pb-4 brands-section" v-if="blogs.length">
      <div class="row">
        <div class="col-12 col-s-title">
          <h2 class="title">Posts destacados</h2>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6 col-lg-4 col-xl-3 col-article-sample-1" v-for="(blog, baInx) in blogs" :key="'ba-'+baInx">
          <router-link class="box-article" :to="'/blog/'+$root._converToURL(blog.title, blog.id)">
            <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url('+blog.imageUrl+')' }">
              <img src="public/images/pages/blog/empty.png">
            </div>

            <div class="box-descr">
              <h5>{{ blog.title }}</h5>
              <div class="descr" v-html="blog.body"></div>
            </div>
          </router-link>
        </div>
      </div>
    </section> -->
    <!--  -->

    <!-- Modal de imagen con publicidad -->
    <!-- <b-modal ref="modal-promo" size="lg" modal-class="modal-home-promo" centered hide-footer title="">
      <img :src="modal">
    </b-modal> -->
    <!--  -->

  </div>
</template>

<script>
import cartComponent from './products/cart-component.vue';
export default {
  components: {
    cartComponent,
  },

  data(){
    return{
      banners:{
        pc:[
          { id: 1, imageUrl: 'public/images/pages/home/banner-1.jpg' },
          { id: 1, imageUrl: 'public/images/pages/home/banner-1.jpg' },
        ],
        movil:[
          { id: 1, imageUrl: 'public/images/pages/home/banner-1-m.jpg' },
          { id: 1, imageUrl: 'public/images/pages/home/banner-1-m.jpg' },
        ]
      },
      banner_middle:[],
      banner_bottom:{},
      products_mostview:[],
      products_popular: [
        { id: 1, imageUrl: 'public/images/pages/products/product-2.jpg', brandImageUrl: 'public/images/pages/home/brands/thermaltake.png', name: 'Gabinete Thermaltake V5000 Tg Argb Air Black Color Negro', stock: 2, sku: '444', price: 850.50 },
        { id: 2, imageUrl: 'public/images/pages/products/product-1.jpg', brandImageUrl: 'public/images/pages/home/brands/thermaltake.png', name: 'Laptop Acer Aspire 3 15.6 Ryzen 7, 16gb/512gb, Windows 11 ', stock: 2, sku: '444', price: 10950.50 },
        { id: 3, imageUrl: 'public/images/pages/products/product-5.jpg', brandImageUrl: 'public/images/pages/home/brands/thermaltake.png', name: 'Combo Ryzen 5 5600g, Gigabyte B550m Ds3h Ac Motherboard 32gb', stock: 2, sku: '444', price: 4800.50 },
        { id: 4, imageUrl: 'public/images/pages/products/product-7.jpg', brandImageUrl: 'public/images/pages/home/brands/thermaltake.png', name: 'Gabinete para computadora de escritorio Asus TUF Gaming GT301 negro', stock: 2, sku: '444', price: 850.50 },
        { id: 5, imageUrl: 'public/images/pages/products/product-6.jpg', brandImageUrl: 'public/images/pages/home/brands/thermaltake.png', name: 'Laptop Touch Lenovo Slim 3 I3-1305u 8gb 512gb', stock: 2, sku: '444', price: 12000.50 },
      ],
      products_new: [
        { id: 5, imageUrl: 'public/images/pages/products/product-6.jpg', brandImageUrl: 'public/images/pages/home/brands/thermaltake.png', name: 'Laptop Touch Lenovo Slim 3 I3-1305u 8gb 512gb', stock: 2, sku: '444', price: 12000.50 },
        { id: 4, imageUrl: 'public/images/pages/products/product-7.jpg', brandImageUrl: 'public/images/pages/home/brands/thermaltake.png', name: 'Gabinete para computadora de escritorio Asus TUF Gaming GT301 negro', stock: 2, sku: '444', price: 850.50 },
        { id: 1, imageUrl: 'public/images/pages/products/product-2.jpg', brandImageUrl: 'public/images/pages/home/brands/thermaltake.png', name: 'Gabinete Thermaltake V5000 Tg Argb Air Black Color Negro', stock: 2, sku: '444', price: 850.50 },
        { id: 2, imageUrl: 'public/images/pages/products/product-1.jpg', brandImageUrl: 'public/images/pages/home/brands/thermaltake.png', name: 'Laptop Acer Aspire 3 15.6 Ryzen 7, 16gb/512gb, Windows 11 ', stock: 2, sku: '444', price: 10950.50 },
        { id: 3, imageUrl: 'public/images/pages/products/product-5.jpg', brandImageUrl: 'public/images/pages/home/brands/thermaltake.png', name: 'Combo Ryzen 5 5600g, Gigabyte B550m Ds3h Ac Motherboard 32gb', stock: 2, sku: '444', price: 4800.50 },
      ],
      products_security: [
        { id: 5, imageUrl: 'public/images/pages/products/product-8.jpg', brandImageUrl: 'public/images/pages/home/brands/tplink.png', name: 'TP-Link Tapo TC40, Cámara de Seguridad Wi-Fi para Exteriores, 1080P con Visión Nocturna', stock: 2, sku: '444', price: 789.00 },
        { id: 4, imageUrl: 'public/images/pages/products/product-9.jpg', brandImageUrl: 'public/images/pages/home/brands/tplink.png', name: 'TP-Link Tapo TC70, Cámara Wi-Fi de Seguridad Interior,1080P, Cámara IP Domo 360°', stock: 2, sku: '444', price: 450.50 },
        { id: 1, imageUrl: 'public/images/pages/products/product-10.jpg', brandImageUrl: 'public/images/pages/home/brands/tplink.png', name: 'TP-Link Tapo TC65, Nuevo cámara de Seguridad Wi-Fi para Exteriores, 3MP, Visión Nocturna', stock: 2, sku: '444', price: 650.00 },
        { id: 5, imageUrl: 'public/images/pages/products/product-8.jpg', brandImageUrl: 'public/images/pages/home/brands/tplink.png', name: 'TP-Link Tapo TC40, Cámara de Seguridad Wi-Fi para Exteriores, 1080P con Visión Nocturna', stock: 2, sku: '444', price: 789.00 },
        { id: 4, imageUrl: 'public/images/pages/products/product-9.jpg', brandImageUrl: 'public/images/pages/home/brands/tplink.png', name: 'TP-Link Tapo TC70, Cámara Wi-Fi de Seguridad Interior,1080P, Cámara IP Domo 360°', stock: 2, sku: '444', price: 450.50 },
        { id: 1, imageUrl: 'public/images/pages/products/product-10.jpg', brandImageUrl: 'public/images/pages/home/brands/tplink.png', name: 'TP-Link Tapo TC65, Nuevo cámara de Seguridad Wi-Fi para Exteriores, 3MP, Visión Nocturna', stock: 2, sku: '444', price: 650.00 },
      ],

      brandsTemp: [
        { id: 1, imageUrl: 'public/images/pages/home/brands/camionerachihuahua.png' },
        { id: 2, imageUrl: 'public/images/pages/home/brands/algodonera.png' },
        { id: 3, imageUrl: 'public/images/pages/home/brands/mining.png' },
        { id: 4, imageUrl: 'public/images/pages/home/brands/colegio.png' },
        { id: 5, imageUrl: 'public/images/pages/home/brands/promoespacio.png' },
        { id: 6, imageUrl: 'public/images/pages/home/brands/elektra.png' },
        { id: 7, imageUrl: 'public/images/pages/home/brands/bancoazteca.png' },
        { id: 7, imageUrl: 'public/images/pages/home/brands/starbucks.png' },
        { id: 7, imageUrl: 'public/images/pages/home/brands/obras.png' },
        { id: 7, imageUrl: 'public/images/pages/home/brands/berel.png' },
        { id: 7, imageUrl: 'public/images/pages/home/brands/minacerro.png' },
        { id: 7, imageUrl: 'public/images/pages/home/brands/diim.png' },
        { id: 7, imageUrl: 'public/images/pages/home/brands/fruver.png' },
        { id: 7, imageUrl: 'public/images/pages/home/brands/gpaaero.png' },
      ],

      /* == Carousel options == */
      bannersOption: {
        loop: true,
        speed:800,
        effect: 'fade',

        pagination: {
          el: '.swiper-pagination',
          dynamicBullets: true,
          clickable: true
        },

        autoplay: {
          delay: 553500,
          disableOnInteraction: false
        },
      },

      productsOption: {
        loop: false,
        slidesPerView: 4,
        spaceBetween: 10,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        breakpoints: {
          1400: {
            slidesPerView: 4
          },
          1200: {
            slidesPerView: 4
          },
          992: {
            spaceBetween: 10,
            slidesPerView: 3
          },
          768: {
            spaceBetween: 20,
            slidesPerView: 2
          },
          1: {
            slidesPerView: 1
          },
        }
      },

      /* == Carousel options == */
      brandsOptions: {
        loop: true,
        slidesPerView: 4,
        spaceBetween: 35,

        autoplay: {
          delay: 4500,
          disableOnInteraction: false
        },

        breakpoints: {
          1300: {
            slidesPerView: 4,
          },
          992: {
            slidesPerView: 4,
          },
          767: {
            slidesPerView: 3,
          },
          470: {
            slidesPerView: 2,
          },
          0: {
            slidesPerView: 1,
          },
        }
      },

      multibanersOptions: {
        loop: true,
        slidesPerView: 1,
        spaceBetween: 30,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        breakpoints: {
          1400: {
            slidesPerView: 4
          },
          992: {
            slidesPerView: 3
          },
          768: {
            slidesPerView: 2
          },
          1: {
            slidesPerView: 1
          },
        },
      }
      // == ==
    }
  },

  methods: {
    getHome: function(){
      axios.get('https://xicode.com.mx/api/home').then(({data}) =>{
        // axios.get(tools.url('/api/home')).then(({data}) =>{
            this.banners = data.banners;
            this.banner_middle = data.banner_middle;
            this.banner_bottom = data.banner_bottom;
            this.brands = data.marcas;
            this.products_mostview = data.products_mostview;
            this.products_popular = data.products_popular;
            /*this.products = data.destacados;
            this.banner1 = data.banner1;
            this.banner2 = data.banner2;
            this.banner3 = data.banner3;
            this.categories = data.categorias;

            this.blogs = data.blog;
            this.ofertas = data.ofertas;
            this.modal = data.modal;
            if(this.modal && this.$root.modal){
                this.$refs['modal-promo'].show();
                this.$root.modal = false;
            }*/
        });
    }
  },

  mounted(){
    // this.getHome();
  }

}
</script>
