<template lang="html">
  <section class="services-section-ab">
    <div class="container">
      <div class="row justify-content-between">
        <div class="col-12 col-title">
          <h2>Nuestros servicios</h2>
        </div>

        <div class="col-12">
          <swiper class="swiper" :options="servicesOptions">
            <swiper-slide v-for="(sh, shInx) in services" :key="'shInx-'+shInx">
              <div class="col-12 px-0 col-service">
                <div class="b-image">
                  <img :src="sh.imageUrl">
                </div>

                <h5>{{ sh.title }}</h5>
                <hr />
                <p>
                  {{ sh.description }}
                </p>
              </div>
            </swiper-slide>

            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      services: [
        {
          imageUrl: 'public/images/pages/about-us/icon-s-1.png',
          title: 'Reparación de equipo informático',
          description: 'En Tecnologías ICSI Estamos siempre comprometidos con la vanguardia y listos para ayudarte., contamos con reparación de equipos de cómputo, diagnóstico, y soporte técnico, equipos de impresión, para que siempre se encuentren en las mejores condiciones óptimas para trabajar, ya sea que eres usuario final, empresa y requieres de personal cualificado para reparar tus equipos.'
        },
        {
          imageUrl: 'public/images/pages/about-us/icon-s-2.png',
          title: 'Mantenimiento preventivo y correctivo',
          description: 'Ofrecemos pólizas de mantenimiento preventivo y correctivo en sitio para empresas, que consisten en contratos de servicio recurrentes a través de nuestro centro de soporte técnico, También contamos con Tickets de soporte por incidencia en sitio, para las empresas que requieren el servicio cada vez que se les presente un incidente el cual requiera de una atención inmediata 24/7.'
        },
        {
          imageUrl: 'public/images/pages/about-us/icon-s-3.png',
          title: 'Atención de Tickets de soporte en sitio 24/7',
          description: 'Nuestra empresa cuenta con ingenieros y técnicos para brindar un soporte técnico especializado y con la experiencia de más de 20 años para la atención de tickets de soporte informático para empresas qué así lo requieran., contratar un servicio de soporte técnico y consultoría IT profesional 24/7., con un tiempo de respuesta inmediata, para atender cualquier ticket, también contamos con soporte en sitio, foráneo, y soporte remoto.'
        },
        {
          imageUrl: 'public/images/pages/about-us/icon-s-4.png',
          title: 'Instalación de redes y cableado estructurado',
          description: 'Especialistas en instalaciones de Cableado Estructurado, por más de 20 años de experiencia nos respaldan, Canalización, venta de Racks, materiales y Accesorios para tu proyecto de cableado que tu empresa requiera. Proveemos equipos y servicios, instalación y diagnóstico de nodos de red, Diagnóstico de fallas en la red, venta de cableado y accesorios para una óptima y correcta instalación de redes empresariales. Nuestro personal está altamente capacitado y cuenta con las herramientas especializadas para la instalación de cualquier proyecto de red.'
        },
        {
          imageUrl: 'public/images/pages/about-us/icon-s-8.png',
          title: 'Respaldos de datos',
          description: 'Un respaldo de datos (también conocido como backup o copia de seguridad) es una segunda (o tercera) copia de sus archivos más importantes por ejemplo sus fotos y videos familiares, documentos y correos electrónicos. En lugar de guardarlos en un solo lugar como memoria usb o su computadora, la copia debe estar en otro lugar seguro, cómo discos SSD ó almacenamiento en servidores en la nube.'
        },
        {
          imageUrl: 'public/images/pages/about-us/icon-s-5.png',
          title: 'Instalación de Cámaras de seguridad',
          description: 'Nuestro principal objetivo es ser su proveedor de sistemas de circuito cerrado CCTV para la industria, negocios, comercios y cliente final que necesitan mantener seguras sus instalaciones, contamos con las mejores marcas y pólizas de mantenimiento preventivo por evento cada vez que lo requiera, apoyamos cualquier proyecto, proveemos cualquier marca de cámaras de seguridad, apoyamos desde la pre-venta, post-venta hasta la instalación de sus equipos.'
        },
        {
          imageUrl: 'public/images/pages/about-us/icon-s-6.png',
          title: 'Administración de proyectos 24/7',
          description: 'Utilice nuestro equipo de ingenieros desplegados a nivel local y nacional como sus ojos y manos remotas para implementar cualquier tipo de solución ó proyecto de soporte informático que su empresa necesite. Siempre listos para apoyarlo en todos sus proyectos., SOPORTE EN SITIO DE TI A TIEMPO TODO EL TIEMPO.'
        },
        {
          imageUrl: 'public/images/pages/about-us/icon-s-7.png',
          title: 'Recuperación de Información',
          description: 'Líderes en la recuperación de datos, fallos lógicos o por virus, dispositivos formateados, borrado accidental, particiones eliminadas, recuperación de datos cómo archivos, fotos, videos, recuperamos tu información de discos duros mecánicos, SSD, Memorias USB, discos externos,etc.'
        },
      ],

      // == Carousel options ==
      servicesOptions: {
        loop: true,
        slidesPerView: 3,
        spaceBetween: 30,

        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          dynamicBullets: true,
        },

        autoplay: {
          delay: 4500,
          disableOnInteraction: false
        },

        breakpoints: {
          992: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 2,
          },
          1: {
            slidesPerView: 1,
          },
        }
      },
      // == ==
    }
  }
}
</script>
