<template lang="html">
  <div>
    <div class="b-cart">
      <b-form @submit="onSubmit">
        <div class="input-container">
          <a class="_btn t-150 btn-plus" @click="changeQuantity('plus')"><i class="fa-solid fa-chevron-up"></i></a>
          <a class="_btn t-150 btn-minus" @click="changeQuantity('minus')"><i class="fa-solid fa-chevron-down"></i></a>
          <b-form-input v-model="form.quantity" type="number" required></b-form-input>
        </div>
        <b-button class="btn-add" type="submit"><i class="fa-solid fa-cart-shopping"></i> Agregar</b-button>
      </b-form>
    </div>

    <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block"  ref="modal">
        <div v-html="modal.msg"></div>
        <div class="col-12 text-center" style="padding-top: 20px;" v-if="modal.icon == 'success'">
            <b-button class="btn btn-primary" slot="button" v-on:click.prevent="$refs.modal.close()">Agregar mas productos</b-button>
            <b-button class="btn btn-primary" slot="button" v-on:click.prevent="$refs.modal.close(); $router.push('/cart')">Ir al carrito <i class="color-white fas fa-shopping-cart"></i></b-button>
        </div>
    </sweet-modal>

    <b-modal modal-class="modal-added-product"  size="lg" ref="modal-added-prod" hide-footer title="" centered>
      <div class="px-lg-5 py-4">
        <p>
          <div class="sweet-modal-icon sweet-modal-success animate">
            <span class="sweet-modal-line sweet-modal-tip animateSuccessTip"></span>
            <span class="sweet-modal-line sweet-modal-long animateSuccessLong"></span>
            <div class="sweet-modal-placeholder"></div>
            <div class="sweet-modal-fix"></div>
          </div>
        </p>
        <p class="mt-5">
          El producto <strong>{{ product.name }}</strong> se agregado al carrito con 1 piezas
        </p>

        <p class="mt-3">
          <b-button class="btn btn-modal my-2 mx-2" slot="button" v-on:click.prevent="$refs['modal-added-prod'].hide()">Agregar mas productos</b-button>
          <b-button class="btn btn-modal my-2 mx-2" slot="button" v-on:click.prevent="$refs['modal-added-prod'].hide(); $router.push('/cart')">Ir al carrito <i class="color-white fas fa-shopping-cart"></i></b-button>
        </p>
      </div>
    </b-modal>

  </div>
</template>

<script>
export default {
  props: ['product'],

  data(){
    return{
      modal:{
        msg:'',
        icon:'',
        block:false
      },

      form: {
        quantity: 1,
      },
    }
  },

  methods: {
    changeQuantity(a) {
      if(a == 'minus') {
        if(this.form.quantity > 1) {
          this.form.quantity--;
        }
      }
      else {
        this.form.quantity++;
      }
    },

    onSubmit(event) {
      event.preventDefault();
      this.$refs['modal-added-prod'].show();
    },
  },

  mounted() {
  }
}
</script>
