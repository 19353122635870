<template lang="html">
  <div id="cart-page-v2">

    <section class="main-section">
      <div class="container">
        <div class="row">

          <!-- Paso 1 | tabla de productos -->
          <div class="col-lg col-table" v-if="$route.params.step == null">
            <div class="box-white">
              <div class="row row-top">
                <div class="col col-cell col-image">Imagen</div>
                <div class="col col-cell col-prod">Producto</div>
                <div class="col col-cell col-lprice">Precio lista</div>
                <div class="col col-cell col-price">Precio unitario</div>
                <div class="col col-cell col-quantity">Cantidad</div>
                <div class="col col-cell col-subtotal">Subtotal</div>
              </div>
              <div class="row row-body" v-for="(product, pInx) in form.products" :key="'pInx-'+pInx">
                <div class="col col-cell col-image"><img src="public/images/pages/products/product-1.jpg" /></div>
                <div class="col col-wr">
                  <div class="col col-cell col-prod">
                    <h6>{{ product.name }}</h6>
                    <p>
                      <span class="stock">Inventario: {{ product.stock }}</span>
                    </p>
                  </div>
                  <div class="col col-cell col-lprice">
                    <h6 class="mb-title">Precio lista</h6>
                    $ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(product.lprice) }} <small>MXN</small>
                  </div>
                  <div class="col col-cell col-price">
                    <h6 class="mb-title">Precio unitario</h6>
                    $ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(product.price) }} <small>MXN</small>
                  </div>
                  <div class="col col-cell col-quantity">
                    <div class="box-quantity">
                      <a class="btn-quantity minus" @click="(product.quantity > 1) ? product.quantity-- : 1;"><i class="fa-light fa-minus"></i></a>
                      <b-form-input
                        v-model="product.quantity"
                        type="number"
                        min="1" :max="product.stock"
                        @input="maxQ(pInx, product.stock)"
                        @paste.prevent
                        @keypress="inputQ($event, product.stock, product.quantity)"
                        required>
                      </b-form-input>
                      <a class="btn-quantity plus" @click="(product.quantity < product.stock) ? product.quantity++ : product.stock"><i class="fa-light fa-plus"></i></a>
                    </div>
                  </div>
                  <div class="col col-cell col-subtotal">
                    <h6 class="mb-title">Subtotal</h6>
                    $ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(( product.price * product.quantity )) }} <small>MXN</small>
                    <p class="mt-1 text-right">
                      <a class="btn-delete"><i class="fa-solid fa-trash"></i></a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-3 box-nav-buttons">
              <router-link type="button" class="btn cart-btn-s1 bg-1" to="/cart/usuario">Siguiente</router-link>
            </div>
          </div>
          <!--  -->

          <!-- Paso 2 | Info del usuario -->
          <div class="col-lg col-user-data" v-if="$route.params.step == 'usuario'">
            <div class="box-white">
              <div class="row mx-0">
                <div class="col-12">
                  <h5 class="cart-subtitle">Datos del usuario</h5>
                  <hr class="hr-line" />
                </div>

                <div class="col-lg-6">
                  <b-form-group class="cart-f-group-s1"
                    label="Nombre:">
                    <b-form-input
                      v-model="form.nombre"
                      type="text"
                      placeholder=""
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>

                <div class="col-lg-6">
                  <b-form-group class="cart-f-group-s1"
                    label="Apellidos:">
                    <b-form-input
                      v-model="form.apellidos"
                      type="text"
                      placeholder=""
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>

                <div class="col-lg-6">
                  <b-form-group class="cart-f-group-s1"
                    label="Correo electrónico:">
                    <b-form-input
                      v-model="form.email"
                      type="email"
                      placeholder=""
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>

                <div class="col-lg-6">
                  <b-form-group class="cart-f-group-s1"
                    label="Teléfono:">
                    <b-form-input
                      v-model="form.phone"
                      type="text"
                      placeholder=""
                      minlength="10"
                      maxlength="10"
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>

                <div class="mt-3 col-12">
                  <h5 class="cart-subtitle">Información de envío</h5>
                  <hr class="hr-line" />
                </div>

                <div class="col-lg-6">
                  <b-form-group class="cart-f-group-s1"
                    label="Calle:">
                    <b-form-input
                      v-model="form.calle"
                      type="text"
                      placeholder=""
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>

                <div class="col-6 col-lg-3">
                  <b-form-group class="cart-f-group-s1"
                    label="No. Exterior:">
                    <b-form-input
                      v-model="form.num_ext"
                      type="number"
                      placeholder=""
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>

                <div class="col-6 col-lg-3">
                  <b-form-group class="cart-f-group-s1"
                    label="No. Interior:">
                    <b-form-input
                      v-model="form.num_int"
                      type="number"
                      placeholder=""
                    ></b-form-input>
                  </b-form-group>
                </div>

                <div class="col-lg-6">
                  <b-form-group class="cart-f-group-s1"
                    label="Colonia:">
                    <b-form-input
                      v-model="form.col"
                      type="text"
                      placeholder=""
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>

                <div class="col-lg-4">
                  <b-form-group class="cart-f-group-s1"
                    label="Código postal:">
                    <b-form-input
                      v-model="form.cp"
                      type="number"
                      placeholder=""
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>

                <div class="col-lg-6">
                  <b-form-group class="cart-f-group-s1"
                    label="Estado:">
                    <b-form-select v-model="form.estado">
                      <b-form-select-option :value="null">Seleccione un Estado</b-form-select-option>
                      <b-form-select-option value="1">Aguascalientes</b-form-select-option>
                      <b-form-select-option value="2">Ciudad de México</b-form-select-option>
                      <b-form-select-option value="3">Jalisco</b-form-select-option>
                      <b-form-select-option value="4">Veracruz</b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </div>

                <div class="col-lg-6">
                  <b-form-group class="cart-f-group-s1"
                    label="Ciudad:">
                    <b-form-select v-model="form.ciudad">
                      <b-form-select-option :value="null">Seleccione una Ciudad</b-form-select-option>
                      <b-form-select-option value="1">Ameca</b-form-select-option>
                      <b-form-select-option value="2">Guadalajara</b-form-select-option>
                      <b-form-select-option value="3">Tlaquepaque</b-form-select-option>
                      <b-form-select-option value="4">Tonalá</b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </div>

                <div class="col-lg-12">
                  <b-form-group class="cart-f-group-s1"
                    label="Referencias:">
                    <b-form-textarea
                      v-model="form.referencias"
                      placeholder=""
                      rows="3"
                      max-rows="3"
                    ></b-form-textarea>
                  </b-form-group>
                </div>
              </div>
            </div>

            <div class="mt-3 box-nav-buttons">
              <router-link type="button" class="btn cart-btn-s1 bg-2" to="/cart/">Anterior</router-link>
              <router-link type="button" class="btn cart-btn-s1 bg-1" to="/cart/pago">Siguiente</router-link>
            </div>
          </div>
          <!--  -->

          <!-- Paso 3 | Formas de pago -->
          <div class="col-lg col-payments" v-if="$route.params.step == 'pago'">
            <div class="box-white">
              <div class="row mx-0">
                <div class="mb-3 col-12">
                  <h5 class="cart-subtitle">Formas de pago</h5>
                  <hr class="hr-line" />
                </div>

                <div class="col-lg-6 col-payment">
                  <div class="box-payment" v-bind:class="{ 'active' : form.formaPago == 'card' }" @click="form.formaPago = 'card'">
                    <div class="col col-text">
                      <h5>Pago con tarjeta</h5>
                    </div>

                    <div class="col col-logos">
                      <img src="public/images/cart-payment-1.svg">
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 col-payment">
                  <div class="box-payment" v-bind:class="{ 'active' : form.formaPago == 'paypal' }" @click="form.formaPago = 'paypal'">
                    <div class="col col-text">
                      <h5>Pago con Paypal</h5>
                    </div>

                    <div class="col col-logos">
                      <img src="public/images/cart-payment-2.svg">
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 col-payment">
                  <div class="box-payment" v-bind:class="{ 'active' : form.formaPago == 'deposito' }" @click="form.formaPago = 'deposito'">
                    <div class="col col-text">
                      <h5>Depósito en efectivo</h5>
                    </div>

                    <div class="col col-logos">
                      <img src="public/images/cart-payment-3.svg">
                    </div>
                  </div>
                </div>

                <div class="col-12 mt-3" v-if="form.formaPago == 'deposito'">
                  <hr class="hr-line" />
                  <h5 class="mb-2 text-center">Depósito en efectivo</h5>

                  <h6>
                    <strong>Paga en Oxxo</strong> la cantidad de <strong><u>$1,450.00</u></strong>. <br />
                    El pago se verá reflejado en un maximo de 48hrs.<br />
                    Al realizar el pago envianos una foto del depósito a traves del correo <u>pago@ejemplo.com</u>
                  </h6>
                </div>
              </div>
            </div>

            <div class="mt-3 box-nav-buttons">
              <router-link type="button" class="btn cart-btn-s1 bg-2" to="/cart/usuario">Anterior</router-link>
              <router-link type="button" class="btn cart-btn-s1 bg-1" to="/cart/gracias">Pagar</router-link>
              <!-- <b-button type="button" class="cart-btn-s1 ml-2 bg-1">Pagar</b-button> -->
            </div>
          </div>
          <!--  -->

          <!-- Menu lateral -->
          <div class="col-lg mt-4 mt-lg-0 col-summary" v-if="$route.params.step != 'gracias'">
            <div class="box-white">
              <div class="box-resume">
                <div class="col-6">
                  <strong class="f-w-600">Resumen:</strong>
                </div>
                <div class="col-6 c-num">
                  125485
                </div>
              </div>

              <div class="box-totals">
                <div class="row">
                  <div class="col-5 c-txt">Subtotal</div>
                  <div class="col-7 pl-0 pr-2 c-num">$2175.74 <small>MXN</small></div>
                </div>
                <div class="row">
                  <div class="col-5 c-txt">Envío</div>
                  <div class="col-7 pl-0 pr-2 c-num">$0.00 <small>MXN</small></div>
                </div>
                <div class="row">
                  <div class="col-5 c-txt">IVA 16%</div>
                  <div class="col-7 pl-0 pr-2 c-num">$348.12 <small>MXN</small></div>
                </div>
                <div class="row row-total">
                  <div class="col-5 c-txt">TOTAL</div>
                  <div class="col-7 pl-0 pr-2 c-num">$2,523.86 <small>MXN</small></div>
                </div>
              </div>
            </div>
          </div>
          <!--  -->

          <!-- Paso 4 | Pagado -->
          <div class="col-12 col-thanks" v-if="$route.params.step == 'gracias'">
            <div class="box-white">
              <p class="mb-4">
                <i class="fa-thin fa-cart-shopping icon-cart"></i>
              </p>
              <h2>Gracias por su compra</h2>

              <h5 class="mt-3">Pedido número <strong>486245-012</strong></h5>

              <p class="mt-4">
                <router-link class="f-w-500" to="/">
                  <i class="fa-sharp fa-solid fa-house-blank"></i> Seguir comprando
                </router-link>
              </p>
            </div>
          </div>
          <!--  -->

        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        products: [
          { id: 1, name: 'Nombre de producto X', quantity: 1, stock: 105, lprice: 1080, price: 1000 },
          { id: 2, name: 'Nombre de producto Y', quantity: 1, stock: 100, lprice: 1080, price: 1000 },
          { id: 3, name: 'Nombre de producto Z', quantity: 1, stock: 200, lprice: 1080, price: 1000 },
          { id: 4, name: 'Nombre de producto A', quantity: 1, stock: 90, lprice: 1580, price: 1500 },
          { id: 5, name: 'Nombre de producto B', quantity: 1, stock: 80, lprice: 1890, price: 1800 },
          { id: 6, name: 'Nombre de producto C', quantity: 1, stock: 100, lprice: 1990, price: 1900 },
          { id: 7, name: 'Nombre de producto D', quantity: 1, stock: 300, lprice: 2100, price: 2000 },
          { id: 8, name: 'Nombre de producto E', quantity: 1, stock: 10, lprice: 784, price: 710 },
        ],

        formaPago: null,
      },
    }
  },

  methods: {
    maxQ(idx, maxStock) {
      if(this.form.products[idx].quantity >= maxStock) {
        setTimeout(() => {
          this.form.products[idx].quantity = maxStock;
        }, 10);
      }
    },

    inputQ(evt) {
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57)
        // && charCode !== 46
      ) {
        evt.preventDefault();
      }
    }
  },
}
</script>
