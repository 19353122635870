<template lang="html">
  <div class="placed-backg contact-page-" id="page-info-t1">
    <div class="bg-image" v-bind:style="{ backgroundImage: 'url(public/images/pages/contact/bg.jpg)' }"></div>

    <section class="container oversized-container content-section">
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-4 text-center col-info">
          <h1 class="mb-2 page-title">Contactos</h1>

          <h6 class="f-w-700">Dirección</h6>
          <p>
            Prv. Río Bósforo No. 25922, Col. Riberas de Sacramento, C.P. 31184 Chihuahua, Chih México.
          </p>

          <br />
          <h6 class="f-w-700">Teléfono</h6>
          <p>
            <strong class="f-w-600">Oficina:</strong> <span>+52 (614) 499 4221</span>
          </p>
          <p>
            <strong class="f-w-600">Celular directo:</strong> <span>+52 (614) 403 5438</span>
          </p>
          <p>
            <strong class="f-w-600">Ventas & Soporte 24/7:</strong> <span>(614) 403 5438</span>
          </p>

          <br />
          <h6 class="f-w-700">Horario de atención</h6>
          <p>
            <strong class="f-w-600">Lunes a Viernes:</strong> 9:00 am a 6:00 pm
          </p>
          <p>
            <strong class="f-w-600">Sabados:</strong> 9:00 am a 2:00 pm
          </p>

          <br />
          <h6 class="f-w-700">Ventas Empresariales y Proyectos</h6>
          <p>
            ventas@tecnologiasicsi.com.mx
          </p>

          <br />
          <h6 class="f-w-700">Siguenos en redes sociales</h6>
          <p>
            <a class="btn-social" target="_blank" href="https://www.facebook.com/OficialTecnologiasicsi"><i class="fab fa-facebook-square"></i></a>
            <a class="btn-social" target="_blank" href="https://www.youtube.com/TecnologiasICSI"><i class="fab fa-youtube"></i></a>
            <a class="btn-social" target="_blank" href="https://twitter.com/tecnologiasicsi"><i class="fab fa-twitter-square"></i></a>
            <a class="btn-social" target="_blank" href="https://www.linkedin.com/in/tecnolog%C3%ADas-icsi-chihuahua-52b76147/"><i class="fab fa-linkedin"></i></a>
            <a class="btn-social" target="_blank" href="https://t.me/OficialTecnologiasICSI"><i class="fab fa-telegram"></i></a>
          </p>
        </div>

        <div class="col-lg-5 col-xl-4 col-info">
          <b-form @submit="onSubmit">
            <b-form-group>
              <b-form-input
                v-model="form.name"
                type="text"
                required
                placeholder="Nombre de contacto"
              ></b-form-input>
            </b-form-group>

            <b-form-group>
              <b-form-input
                v-model="form.email"
                type="email"
                required
                placeholder="Correo de empresa"
              ></b-form-input>
            </b-form-group>

            <b-form-group>
              <b-form-input
                v-model="form.phone"
                type="text"
                required
                placeholder="Teléfono"
              ></b-form-input>
            </b-form-group>

            <b-form-group>
              <b-form-input
                v-model="form.subject"
                type="text"
                required
                placeholder="Asunto"
              ></b-form-input>
            </b-form-group>

            <b-form-group>
              <b-form-input
                v-model="form.company"
                type="text"
                required
                placeholder="Nombre de la empresa"
              ></b-form-input>
            </b-form-group>

            <b-form-group>
              <b-form-textarea
                v-model="form.msg"
                placeholder="Escriba su mensaje"
                required
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>

            <p class="text-center">
              <b-button type="submit" class="btn-send">Enviar</b-button>
            </p>
          </b-form>

        </div>
      </div>
    </section>

    <!-- <section class="container-fluid px-0 map-section">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d119452.69716917786!2d-103.40545358012501!3d20.67377770932267!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428b18cb52fd39b%3A0xd63d9302bf865750!2sGuadalajara%2C%20Jal.%2C%20M%C3%A9xico!5e0!3m2!1ses!2sca!4v1627506372341!5m2!1ses!2sca" allowfullscreen="" loading="lazy"></iframe>
    </section> -->
  </div>
</template>

<script>
export default {
  data(){
    return{
      form: {
        name: null,
        email: null,
        phone: null,
        subject: null,
        company: null,
        msg: null
      },
      info: {
        contact:null,
      },
    }
  },

  methods: {
    makeToast(variant = null, msg, title) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-right',
        appendToast: true
      })
    },

    getInfo: function(){
      axios.get(tools.url('/api/info')).then((response)=>{
        this.info = response.data;
      }).catch((error)=>{
        console.log(error);
      })
    },

    onSubmit(evt) {
      evt.preventDefault();
      //var data=tools.params(form, this.form);
      axios.post(tools.url("/api/contact"), this.form).then((response)=>{
          this.makeToast('default', response.data.response, 'Mensaje enviado');
          this.form = {
            name: null,
            email: null,
            msg: null,
            check: null
          };
        }).catch( (error)=> {
        // console.log(error.response);
        let errors = error.response.data;
        // console.log(errors);
        this.makeToast('danger', error.response.data.name, 'Error');
        this.makeToast('danger', error.response.data.email, 'Error');
        this.makeToast('danger', error.response.data.phone, 'Error');
        this.makeToast('danger', error.response.data.msg, 'Error');
      });
    }
  },

  beforeMount(){
    // this.getInfo();

    if(this.$route.query.p){
      this.form.msg = 'Me intresa el producto '+ this.$route.query.p;
    }
  }
}
</script>
