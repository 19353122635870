<template lang="html">
  <section class="values-section-ab">
    <div class="container">
      <div class="row">
        <div class="col-12 col-title">
          <h2>Nuestros valores</h2>
        </div>

        <div class="col-sm-6 col-lg-3 col-value">
          <div class="b-image">
            <img src="public/images/pages/about-us/icon-1.png">
          </div>

          <h5>Honestidad</h5>
        </div>

        <div class="col-sm-6 col-lg-3 col-value">
          <div class="b-image">
            <img src="public/images/pages/about-us/icon-2.png">
          </div>

          <h5>Soporte al cliente</h5>
        </div>

        <div class="col-sm-6 col-lg-3 col-value">
          <div class="b-image">
            <img src="public/images/pages/about-us/icon-3.png">
          </div>

          <h5>Seguridad</h5>
        </div>

        <div class="col-sm-6 col-lg-3 col-value">
          <div class="b-image">
            <img src="public/images/pages/about-us/icon-4.png">
          </div>

          <h5>Compromiso</h5>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
}
</script>
