<template lang="html">
  <div class="placed-backg faqs-page" id="page-info-t1">
    <!-- <div class="bg-image"></div> -->
    <section class="container oversized-container content-section">
      <div class="row align-items-center justify-content-center" v-bind:class="{ 'tabla-page' : $route.params.table == 'tabla' }">
        <div class="col-lg-10 col-info" id="faqs-box">
          <h1 class="mb-2 page-title">Aviso de Privacidad</h1>
          <!-- <div class="text-justify" v-html="content"></div> -->
          <div class="text-justify">
            <h6>Responsable del tratamiento de datos: </h6>
            <p>
              Ingeniería Computacional y Servicios Integrados ICSI, S.A de C.V. con domicilio en Privada Río Bósforo # 25922, Chihuahua, Chihuahua, México C.P. 31184, con correo electrónico: website@tecnologiasicsi.com.mx, teléfono (614) 499-4221, será el responsable del tratamiento de sus datos personales<br />
              <br />
              Para nosotros tratar sus datos personales de manera legítima resulta un tema prioritario. El presente aviso de privacidad integral complementa otros avisos de privacidad simplificados que nuestra organización haya puesto a su disposición y resulta supletorio en todo aquello que expresamente no refieran tales avisos.
            </p>

            <br />
            <h5>Finalidades del tratamiento de los datos personales.</h5>
            <p>
              Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades que son necesarias para el servicio que solicita:
            </p>
            <ul class="mb-0">
              <li>Elaboración de bases de datos de posibles clientes.</li>
              <li>Ofertas promocionales.</li>
              <li>Mercadotecnia/publicitario</li>
              <li>Prospección comercial</li>
              <li>Creación y generación de bases de datos de asistentes a eventos</li>
              <li>Para consulta y verificación de los datos personales proporcionados voluntariamente por las personas que visitan nuestra página web</li>
              <li>Para establecer contacto con las personas que soliciten información sobre cualquiera de nuestros proyectos, productos o servicios.</li>
              <li>Para dar seguimiento y atención a consultas sobre los servicios a los proyectos</li>
              <li>Para recabar datos estadísticos sobre nuestros visitantes.</li>
              <li>Para informar al público de nuevos productos.</li>
            </ul>

            <br />
            <h5>Datos personales que recabamos </h5>
            <p>
              Para las finalidades antes mencionadas, recabamos sus datos personales cuando usted nos los proporciona voluntariamente a través de nuestra página web en Internet o cuando nos los proporciona personal y directamente mediante formatos digitales y/o impresos. Solo en algunas ocasiones, obtenemos sus datos personales de manera indirecta, a través de otras fuentes que se encuentran permitidas por la ley como son: por teléfono o a través de correo electrónico. Los Datos Personales que obtenemos de usted son los siguientes:
            </p>
            <ol class="mb-0">
              <li>Nombre</li>
              <li>Puesto</li>
              <li>Empresa</li>
              <li>Teléfono de casa u oficina</li>
            </ol>

            <br />
            <p>
              En caso de contar con ese medio y deseé ser así contactado número de teléfono celular; Correoelectrónico personal o correo de institución para fines de contacto. <br>
              Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, utilizaremos los siguientes datos personales:
            </p>
            <ul class="mb-0">
              <li>Nombre</li>
              <li>Teléfono particular</li>
              <li>Teléfono celular</li>
              <li>Correo electrónico</li>
              <li>Correo electrónico institucional</li>
              <li>Teléfono institucional</li>
            </ul>

            <br />
            <h5>Datos Personales Sensibles que recabamos </h5>
            <p>
              En ningún caso recabaremos de usted datos personales sensibles, en caso de hacerlo, se establecerá dentro del aviso de privacidad que hagamos llegar para tal finalidad.
            </p>

            <br />
            <h5> Recolección de datos al navegar en sitios y páginas web  </h5>
            <p>
              Dentro de las herramientas de captura automática de datos utilizadas por “Ingeniería Computacional y Servicios Integrados ICSI, S.A de C.V” en sus sitios y páginas web se encuentran las cookies, los web beacons, y los enlaces en los correos electrónicos.<br />
              Uso de Cookies.-  El correcto funcionamiento de los sitios de “Ingeniería Computacional y Servicios Integrados ICSI, S.A de C.V” requieren de la habilitación de “cookies” en su navegador de Internet. Las «cookies» son pequeños archivos de datos transferidos por el sitio Web al disco duro de su computadora cuando navega por el sitio.<br />
              Las cookies pueden ser de sesión o permanentes. Las cookies de sesión no se quedan en su computadora después de que usted cierra la sesión de su navegador, mientras que las cookies permanentes se quedan en las computadoras hasta que se eliminan o caducan.<br />
              En la mayoría de los navegadores las cookies se aceptan automáticamente en virtud de su configuración predeterminada, usted puede ajustar las preferencias de su navegador para aceptar o rechazar las cookies. La desactivación de las cookies puede inhabilitar diversas funciones de los sitios web de “Ingeniería Computacional y Servicios Integrados ICSI, S.A de C.V” o provocar que no se muestren correctamente.<br />
              En caso de que usted prefiera eliminar la información de las cookies enviadas por “Ingeniería Computacional y Servicios Integrados ICSI, S.A de C.V”. Usted puede eliminar el archivo(s) al final de cada sesión del explorador.
            </p>
            <br />
            <p>
              Información relevante puede ser consultada en los sitios de los principales navegadores de Internet.<br />
              Uso de Web beacons (también conocidos como etiquetas de Internet, etiquetas de píxel y clear GIFs).  “Ingeniería Computacional y Servicios Integrados ICSI, S.A de C.V” puede utilizar en su sitio web y en sus correos electrónicos con formato HTML los Web beacons, solos o en combinación con las cookies, para recopilar información sobre el uso de los sitios web y su interacción con el correo electrónico.
            </p>
            <br />
            <p>
              El Web beacon es una imagen electrónica, llamada de un solo píxel (1×1) o GIF que puede reconocer información que es procesada en su computadora, como el caso de las cookies, la hora y fecha en que el sitio y sus secciones son visualizados.
            </p>
            <br />
            <p>
              Vínculos en los correos electrónicos de “Ingeniería Computacional y Servicios Integrados ICSI, S.A de C.V”. Los correos electrónicos que incluyen vínculos que permiten a “Ingeniería Computacional y Servicios Integrados ICSI, S.A de C.V” saber si usted activó dicho vínculo y visitó la página web de destino, pudiendo esta información ser incluida en su perfil.
            </p>
            <br />
            <p>
              En caso de que usted prefiera que “Ingeniería Computacional y Servicios Integrados ICSI, S.A de C.V” no recolecte información sobre su interacción con dichos vínculos, usted puede optar por modificar el formato de las comunicaciones de “Ingeniería Computacional y Servicios Integrados ICSI, S.A de C.V” (por ejemplo, que el mensaje sea recibido en formato de texto y no en formato HTML) o puede hacer caso omiso del vínculo y no acceder a su contenido.
            </p>
            <br />
            <p>
              Los correos electrónicos de “Ingeniería Computacional y Servicios Integrados ICSI, S.A de C.V” pueden incluir vínculos diseñados para dirigirlo a las secciones relevantes de los sitios Web, al redireccionarlo a través de los servidores de “Ingeniería Computacional y Servicios Integrados ICSI, S.A de C.V”. El sistema de redireccionamiento permite a “Ingeniería Computacional y Servicios Integrados ICSI, S.A de C.V” modificar el URL de tales vínculos en caso de ser necesario, también permiten a “Ingeniería Computacional y Servicios Integrados ICSI, S.A de C.V” el determinar la eficacia de sus campañas de marketing en línea.
            </p>

            <br />
            <h5> Opciones y medios para limitar el uso o divulgación de datos personales </h5>
            <p>
              Usted puede dejar de recibir mensajes por correo electrónico, impresos, por teléfono fijo o celular comunicándose con nuestro departamento de mercadotecnia a través del teléfono fijo (614)499-4221 o mediante el envío de un correo electrónico: website@tecnologiasicsi.com.mx
            </p>

            <br />
            <h5>Medios para ejercer los derechos de acceso, rectificación, cancelación u oposición, (ARCO) </h5>
            <p>
               Usted tiene el derecho de acceder a sus datos personales que poseemos y a los detalles del tratamiento de los mismos, así como a rectificarlos en caso de ser inexactos o incompletos; cancelarlos cuando resulten ser excesivos o innecesarios para las finalidades que justificaron su obtención u oponerse al tratamiento de los mismos para fines específicos.
            </p>
            <p>
              Los mecanismos que se han implementado para el ejercicio de dichos derechos son a través de la presentación de la solicitud (“Solicitud de Ejercicio de Derechos ARCO”) a nuestro Departamento de Mercadotecnia, ya sea personalmente en el domicilio de “Ingeniería Computacional y Servicios Integrados ICSI, S.A de C.V” mismo señalado previamente. Usted puede obtener el formato de solicitud, vía telefónica para que le sea enviado o solicitándolo mediante el envío de correo electrónico a la dirección:
            </p>
            <p>
              website@tecnologiasicsi.com.mx a la cuenta que usted nos proporcione.
            </p>

            <br />
            <p>
              Para el ejercicio de sus derechos, usted debe presentar una solicitud (“Solicitud de Ejercicio de Derechos ARCO”) al Departamento de Protección de Datos Personales, acompañando la siguiente información:
            </p>
            <ol class="mb-0">
              <li>Nombre y domicilio completo y correo electrónico para recibir notificaciones;</li>
              <li>Identificación con la que acredite su personalidad (Credencial para votar emitida por el Instituto Nacional Electoral [“INE”], Pasaporte vigente, Cédula Profesional, o en caso de ser de nacionalidad extranjera, su documento migratorio vigente);</li>
              <li>En caso de no ser el titular quien presente la solicitud, el documento que acredite la existencia de la representación, es decir instrumento público o carta poder firmada ante dos testigos, junto con la identificación del titular y del representante legal (Credencial del INE, Pasaporte vigente, Cédula Profesional, o en caso de ser de nacionalidad extranjera, su documento migratorio vigente).</li>
              <li>Descripción clara y precisa de los datos personales respecto de los cuales busca ejercer alguno de los Derechos ARCO, cuál es el derecho a ejercer;</li>
              <li>
                En caso de solicitar una rectificación de datos, se indicarán también las modificaciones a realizarse.<br />
                El Departamento Jurídico responderá su solicitud mediante correo electrónico o personalmente en el domicilio del responsable en un término de 20 (veinte) días hábiles contados a partir de que se le envíe acuse de recibo de la misma. En caso de que la solicitud sea procedente, la respuesta podrá enviarse vía correo electrónico o de forma personal. “Ingeniería Computacional y Servicios Integrados ICSI, S.A de C.V” podrá solicitarle información adicional para poder darle una respuesta.  Si pasado dicho término usted no ha presentado los documentos, su solicitud se archivará y el Aviso de Privacidad continuará vigente hasta en tanto no se presente una nueva solicitud.
                <ol>
                  <li>Para el ACCESO de los datos: dentro de un plazo de 15 (quince) días contados a partir de la respuesta afirmativa hecha por el Departamento Jurídico.</li>
                  <li>Para la RECTIFICACIÓN de los datos: dentro de un plazo de 15 (quince) días contados a partir de la respuesta afirmativa hecha por el Departamento Jurídico.</li>
                  <li>Para la CANCELACIÓN U OPOSICIÓN de los datos: se hará primero un bloqueo de los mismos, desde el momento en que se hizo el cotejo de la documentación requerida, en donde el acceso a los datos personales estará restringido a toda persona hasta que haya una respuesta a la solicitud ya sea afirmativa o negativa, en el primer caso dentro de un plazo de 15 (quince) días contados a partir de la respuesta afirmativa hecha por el Departamento Jurídico, y en el segundo caso se hará el desbloqueo de los mismos paracontinuar con el tratamiento.</li>
                </ol>
                <p>
                  Los plazos referidos en los incisos anteriores se podrán prorrogar una sola vez por un período igual en caso de ser necesario y previa notificación hecha por el Departamento Jurídico.
                </p>
              </li>
            </ol>

            <br />
            <h5>Negativa</h5>
            <p>
              “Ingeniería Computacional y Servicios Integrados ICSI, S.A de C.V” podrá negar el ejercicio de los Derechos ARCO, en los siguientes supuestos:
            </p>
            <ul>
              <li>Cuando usted no sea el titular de los datos personales, o no haya acreditado la representación del titular;</li>
              <li>Cuando sus datos personales no obren en la base de datos de “Ingeniería Computacional y Servicios Integrados ICSI, S.A de C.V”;</li>
              <li>Cuando se lesionen los derechos de un tercero;</li>
              <li>Cuando exista un impedimento legal o la resolución de una autoridad competente que restrinja sus Derechos ARCO;</li>
              <li>Cuando la rectificación, cancelación u oposición haya sido previamente realizada.</li>
            </ul>
            <p>
              La Negativa podrá ser parcial, en cuyo caso “Ingeniería Computacional y Servicios Integrados ICSI, S.A de C.V” efectuará el acceso, rectificación, cancelación u oposición en la parte procedente. El ejercicio de los «Derechos ARCO» será gratuito, únicamente deberá de cubrir los gastos justificados de envío o el costo de reproducción en copias u otros formatos. Para mayor información, favor de contactar al Departamento de Mercadotecnia en domicilio, correo electrónico o teléfono señalados con anterioridad.
            </p>

            <br />
            <h5> Mecanismos y procedimientos para que el titular, en su caso, revoque su consentimiento en cualquier momento</h5>
            <p>
              En cualquier momento del tratamiento usted podrá revocar el consentimiento que ha otorgado para el tratamiento de sus datos, para ello es necesario que contacte al Departamento de Mercadotecnia, donde le será indicado el procedimiento que debe seguir para realizar la revocación de su consentimiento.
            </p>

            <br />
            <h5> Transferencias de datos personales dentro de México y al extranjero </h5>
            <p>
              Sus datos se encuentran protegidos en virtud de que nuestras filiales y subsidiarias, operarán bajo la misma Política de Protección de Datos Personales.<br />
              En todo caso su información será compartida solamente para las finalidades citadas en este Aviso de Privacidad.<br />
              Nos comprometemos a no transferir su información personal a terceros sin su consentimiento, salvo las excepciones previstas en el artículo 37 de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, así como a realizar esta transferencia en los términos que fija esa ley.
            </p>

            <br />
            <h5>Medidas de seguridad implementadas</h5>
            <p>
              Para la protección de sus datos personales hemos instrumentado medidas de seguridad de carácter administrativo, físico y técnico con el objeto de evitar pérdidas, mal uso o alteración de su información.<br />
              Cuando realizamos algún proyecto con otra institución, requerimos y verificamos que cuenten con las medidas de seguridad necesarias para proteger sus datos personales, prohibiendo el uso de su información personal para fines distintos a los encargados; lo anterior en el entendido que cualquier incumplimiento por dichos terceros a lo previsto en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares y su Reglamento, o cualquier otra legislación aplicable en caso de ser una entidad gubernamental, es exclusiva responsabilidad de dichos terceros.
            </p>

            <br />
            <h5> Uso de imágenes, fotografías, sonido o material recabado por cualquier medio </h5>
            <p>
              Las imágenes, fotografías, sonido o material que se recabe, por cualquiera de los fines establecidos en nuestros avisos de privacidad simplificados o el presente, serán tratados y protegidos en los términos previstos en este Aviso de Privacidad Integral.<br />
               A fin de cumplir con el principio de información “Ingeniería Computacional y Servicios Integrados ICSI, S.A de C.V” coloca avisos simplificados en su propaganda o publicidad.
            </p>

            <br />
            <h5>Modificaciones al Aviso de Privacidad </h5>
            <p>
              Nos reservamos el derecho de efectuar en cualquier momento modificaciones o actualizaciones al presente Aviso de Privacidad Integral.<br />
              Estas modificaciones estarán disponibles al público a través de los siguientes medios:<br />
              En las oficinas y establecimientos de la empresa y en nuestra página electrónica o sitio web
              en Internet www.tecnologiasicsi.com.mx
            </p>

            <br />
            <h5>Derecho de promover los procedimientos de protección de derechos y de verificación que sustancia el Instituto</h5>
            <p>
              Cualquier queja o información adicional respecto al tratamiento de sus datos personales o duda en relación con la Ley Federal de Protección de Datos Personales en Posesión de los Particulares o con su Reglamento, podrá dirigirla al IFAI.<br />
              Para mayor información visite www.ifai.org.mx<br />
              Fecha última de actualización: 25/07/2022.
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data(){
    return{
      content: '',
    }
  },

  methods: {
    getContent: function(){
      axios.get(tools.url('/api/privacy')).then((response)=>{
        this.content = response.data;
      }).catch((error)=>{
        console.log(error);
      })
    }
  },

  beforeMount(){
    this.getContent();
  }
}
</script>
