<template lang="html">
  <div id="results-page">

    <section class="breadcrumb-section">
      <div class="container oversized-container">
        <span>Marcas</span>
        <span class="line">/</span>
        <span>{{ brand.name }}</span>
      </div>
    </section>

    <section class="container oversized-container products-section">
      <div class="row mb-3">
        <div class="col-12 col-s-title">
          <h2 class="title">{{ brand.name }}</h2>
        </div>
      </div>

      <div class="row">
        <!-- Results -->
        <div class="col-12">
          <div class="row sample-products-wrapper xxl-5e">
            <div class="col-sm-6 col-md-4 col-lg-3 sample-product shad-prod" v-for="(product, index) in products" :key="index">
              <router-link class="outlined-box" :to="'/producto/'+ $root._converToURL(product.name, product.id)">
                <div class="box-image">
                  <div class="out-of-stock" v-if="product.stock < 1">Agotado</div>

                  <div class="placed-backg img" v-bind:style="{ backgroundImage: 'url('+product.img+')' }">
                    <img src="public/images/shared/empty.png" >
                  </div>
                </div>

                <div class="box-shot-descr">
                  <h6 class="brand">{{ product.sku }}</h6>
                  <h6 class="name">{{ product.name }}</h6>
                  <!-- <h6 class="price discount"><span>$ {{ product.price }}</span></h6> -->
                  <h6 class="price">{{ product.price }}</h6>
                </div>
                <p class="p-button">
                  <a class="btn-more">Ver más</a>
                </p>
              </router-link>
            </div>

            <h5 v-if="!products.length && !isLoading">No se encontraron resultados</h5>
          </div>
        </div>
        <!--  -->
        <loading-component v-if="isLoading"></loading-component>

      </div>
    </section>

  </div>
</template>

<script>
import LoadingComponent from '../shared/loading-component.vue';
export default {
  data(){
    return{
      isLoading: true,
      products: [],

      filters: {
        keywords: '',
      },

      brand: [],
      brand_id:null,
    }
  },

  components: {
    LoadingComponent
  },

  watch: {
    "$route.params.id" : function(v){
      this.brand_id = this.$root._getURLID(this.$route.params.id);
      if(this.brand_id){
          this.products = [];
          this.getProductsBrand(this.brand_id);
      }
    },
  },

  methods: {
    getProducts: function(){
      axios.get(tools.url('/api/products')).then((response)=>{
        this.products = response.data;
        this.isLoading = false;
      }).catch((error)=>{
        console.log(error);
      })
    },

    getProductsBrand: function(id){
      axios.get(tools.url('/api/products/brand/'+id)).then((response) =>{
          this.products = response.data.products;
          this.brand = response.data.brand;
          this.isLoading = false;
      }).catch((error)=>{
        console.log(error);
      });
    }
  },

  mounted(){
    if(this.$route.params.id){
      this.brand_id = this.$root._getURLID(this.$route.params.id);
      this.getProductsBrand(this.brand_id);
    }
  },
}
</script>
