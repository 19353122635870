<template lang="html">
  <div id="about-us-page-v2">

    <section class="container oversized-container main-section">
      <div class="row align-items-center">
        <div class="col-lg-5 col-image">
          <img src="public/images/pages/about-us/image.png">
        </div>

        <div class="col-lg-5 offset-lg-1 col-info">
          <div class="col-s-title">
            <h2 class="title">Bienvenido a Tecnologías ICSI</h2>
          </div>
          <div>
            <h6 class="subtitle">SOMOS UNA EMPRESA ESPECIALIZADA EN INFORMÁTICA Y SOLUCIONES PRODUCTIVAS PARA LA INDUSTRIA</h6>
            <p class="mt-3">
              Somos una empresa que ofrece soluciones vanguardistas en informática, redes, telecomunicaciones y cableado estructurado para la industria, empresas y negocios comerciales.
            </p>

            <p class="mt-2">
              Nuestras soluciones integrales incluyen consultoría, desarrollo, implementación y soporte post-venta.
            </p>

            <p class="mt-2">
              Contamos con un equipo de ingenieros y técnicos profesionales en informática, con amplia experiencia por más de 20 años en cada
              una de las áreas asignadas, para ofrecer una experiencia de servicio única.
            </p>
          </div>
        </div>
      </div>
    </section>

    <ServicesComponentv2></ServicesComponentv2>

    <ValueComponent></ValueComponent>

    <BrandsComponentv2></BrandsComponentv2>

    <BrandsComponentv1></BrandsComponentv1>

    <section class="download-section">
      <div class="container">
        <h2 class="mb-2 title">Ingeniería Computacional y Servicios Integrados ICSI S.A. de C.V.</h2>

        <div class="row">
          <div class="col-lg-8 mx-auto">
            <p class="mb-4">
              Descarga nuestro catálogo de productos y servicios así como la integración de soluciones empresariales en TI que tenemos para tu empresa
            </p>
            <a class="t-150 btn-download" target="_blank" href="public/pdfs/portafolio.pdf">Descargar Portafolio</a>
          </div>
        </div>
      </div>
    </section>

    <section class="map-section">
      <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3497.3440634415915!2d-106.164713!3d28.768995!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86ea6a9b52727ef9%3A0x85c33ac772e5aa64!2sTecnolog%C3%ADas%20ICSI%20-%20Soluciones%20en%20inform%C3%A1tica%20y%20Telecomunicaciones.!5e0!3m2!1ses-419!2smx!4v1720566774679!5m2!1ses-419!2smx" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </section>
  </div>
</template>

<script>
import BrandsComponentv2 from './brands-component-v2.vue';
import ValueComponent from './values-component.vue';
import ServicesComponentv2 from './services-component-v2.vue';
import BrandsComponentv1 from './brands-component-v1.vue';
export default {
  components: {
    BrandsComponentv2,
    ValueComponent,
    ServicesComponentv2,
    BrandsComponentv1,
  },

  data(){
    return{
      content:null,
      image: null,
    }
  },

  methods: {
    getContent: function(){
      axios.get(tools.url('/api/about')).then((response)=>{
        this.content = response.data.body;
        this.image = response.data.image;
      }).catch((error)=>{
        console.log(error);
      })
    }
  },

  beforeMount(){
    // this.getContent();
  }
}
</script>
